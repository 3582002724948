import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UnitRootTestZA, UnitRootTestZACard } from "src/generated-sources";

@Component({
    selector: 'unit-root-test-za-card-help',
    templateUrl: './unit-root-test-za-card-help.component.html',
    styleUrls: ['./unit-root-test-za-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitRootTestZACardHelpComponent {
    @Input() params: UnitRootTestZACard;

    get stationarityLabel(): string {
        switch (this.params.regressionMode) {
            case UnitRootTestZA.RegressionMode.CONSTANT_ONLY:
                return "break stationary";

            case UnitRootTestZA.RegressionMode.TREND_ONLY:
            case UnitRootTestZA.RegressionMode.CONSTANT_WITH_TREND:
                return "trend and break stationary";
        }
    }
}
