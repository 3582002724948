import { Injectable } from '@angular/core';
import { ImagePositionInformation, PainterImage, PainterImageArtifact } from '@shared/models/painter';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { BoxHelper } from '@features/deephub/object-detection/utils/box-helper';
import { DeepHubColumnFormat } from 'src/generated-sources';
import { AbstractDeephubObjectDetectionReportPainterService, EnrichedType } from './abstract-deephub-object-detection-report-painter.service';
import { DeephubObjectDetectionReportCellData } from './deephub-object-detection-report-data-fetcher.service';

@Injectable()
export class DeephubObjectDetectionReportImagePainterService extends AbstractDeephubObjectDetectionReportPainterService<PainterImage> {

    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }

    paint(cellData: DeephubObjectDetectionReportCellData, image: PainterImage, imagePosition: ImagePositionInformation): void {
        this.drawBoxesForEnriched(image, imagePosition, cellData.enrichedValid, EnrichedType.VALID);
    }

    protected drawBoxesForEnriched(image: PainterImage, imagePosition: ImagePositionInformation, enriched: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[], type: string): void {
        enriched.forEach((item: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem) => {
            if (item.groundTruth !== undefined) {
                const box = BoxHelper.createBox(PainterImageArtifact, item.groundTruth, imagePosition, {
                    borderColor: this.colorMapService.mapping.get(item.groundTruth.category),
                    borderWidth: '2px',
                    borderStyle: 'dashed'
                }, 'px');
                if (box !== null) {
                    image.addArtifact(box);
                }
            }

            if (item.detection !== undefined) {
                const box = BoxHelper.createBox(PainterImageArtifact, item.detection, imagePosition, {
                    borderColor: this.colorMapService.mapping.get(item.detection.category),
                    borderWidth: '2px',
                    borderStyle: 'solid'
                }, 'px');
                if (box !== null) {
                    image.addArtifact(box);
                }
            }
        });
    }
}
