import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { DeephubImagesDataService, HeatmapParams } from 'src/generated-sources';
import { DeephubObjectDetectionReportDataFetcherService } from '../services/deephub-object-detection-report-data-fetcher.service';
import { DeephubObjectDetectionReportService, ObjectDetectionReport } from '../services/deephub-object-detection-report.service';

interface MatrixData {
    data: number[][];
    xCategories: string[];
    yCategories: string[];
    params: HeatmapParams
}

@UntilDestroy()
@Component({
  selector: 'deephub-object-detection-report-confusion-matrix',
  templateUrl: './deephub-object-detection-report-confusion-matrix.component.html',
  styleUrls: ['./deephub-object-detection-report-confusion-matrix.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionReportConfusionMatrixComponent implements OnInit {
    matrix$: Observable<MatrixData>;
    filterMatrix$: Observable<MatrixData>;
    categories: string[] = [];
    filteredCategoryMap: {
        [key: string]: boolean
    }

    readonly NO_DETECTION_LABEL = 'Not Detected';
    readonly NO_GROUND_TRUTH_LABEL = 'Not an object';

    form = this.fb.group({
        selectedCategories: new FormControl()
    })

    constructor(
        private objectDetectionService: DeephubObjectDetectionReportService,
        private dataFetcher: DeephubObjectDetectionReportDataFetcherService,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.matrix$ = this.objectDetectionService.getReport()
            .pipe(
                debounceTime(400),
                distinctUntilChanged((prev, curr) => prev.iou === curr.iou && prev.confidenceThreshold === curr.confidenceThreshold),
                map(report => this.createMatrix(report)),
            );

        this.form.get('selectedCategories')!.valueChanges.pipe(
            untilDestroyed(this)
        ).subscribe(
            selectedCategories => {
                this.filteredCategoryMap = (selectedCategories || []).reduce((map: { [key: string]: boolean }, category: string) => {
                    map[category] = true;
                    return map;
                }, {});
            }
        );
    }

    matrixClick(clickedCell: { xIndex: number, yIndex: number} | null): void {
        const sorting = {sortBy: DeephubImagesDataService.SortBy.IOU, ascending: false};
        this.dataFetcher.setFilteredPair(clickedCell ? {
            groundTruth: this.objectDetectionService.getFilterCategory(clickedCell.yIndex, this.categories),
            detection: this.objectDetectionService.getFilterCategory(clickedCell.xIndex, this.categories),
            sorting
        } : { sorting });
    }

    createMatrix(report: ObjectDetectionReport): MatrixData {
        this.categories = report.categories;
        const iouIndex = this.objectDetectionService.iouToIndex(report.iou);
        const confidenceThresholdIndex = this.objectDetectionService.confidenceThresholdToIndex(report.confidenceThreshold!);
        const confusionMatrix = (report.perf.confusion_matrix.confusionMatrices[iouIndex] || [])[confidenceThresholdIndex];
        const matrixSize = this.categories.length;
        const newMatrix: number[][] = Array(matrixSize + 1).fill(0).map(() => Array(matrixSize + 1).fill(0)); // include non-detected/non-object

        // initialized non-object and not detected cells
        report.perf.confusion_matrix.perConfidenceScoreDetectionsCount.forEach((counts, index) => {
            newMatrix[index][matrixSize] = counts !== null ? counts[confidenceThresholdIndex] : 0;
        })
        newMatrix[matrixSize] = report.perf.confusion_matrix.groundTruthsCounts.slice().concat(0);

        (confusionMatrix || []).forEach((cell: number[]) => {
            const predictedIndex = cell[0];
            const groundTruthIndex = cell[1];
            const frequency = cell[2];

            newMatrix[predictedIndex][groundTruthIndex] = frequency;

            // calculate non-objects
            newMatrix[predictedIndex][matrixSize] = newMatrix[predictedIndex][matrixSize] - frequency;

            // calculate not detected
            newMatrix[matrixSize][groundTruthIndex] = newMatrix[matrixSize][groundTruthIndex] - frequency;
        });

        return {
            data: newMatrix,
            xCategories: [...this.categories, this.NO_DETECTION_LABEL],
            yCategories: [...this.categories, this.NO_GROUND_TRUTH_LABEL],
            params: {
                filterVariablesWithoutValues: false,
                showAbsValues: true,
                showColors: true,
                showValues: false,
                threshold: 0
            }
        };
    }
}
