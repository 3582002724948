import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {Grouping} from './../../grouping/grouping';
import {_AvailableResult} from './../available-result';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp
 */
export interface KsTest2Samp extends Computation._UnivariateComputation {
    grouping: Grouping;

    // PolyJson type
    type: 'ks_test_2samp';
}

export function isKsTest2Samp(toTest: Computation): toTest is KsTest2Samp { return 'ks_test_2samp' === toTest.type; }

export namespace KsTest2Samp {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.univariate.KsTest2Samp$KsTest2SampResult
     */
    export interface KsTest2SampResult extends _AvailableResult {
        pvalue: number;
        statistic: number;

        // PolyJson type
        type: 'ks_test_2samp';
    }

    export function isKsTest2SampResult(toTest: ComputationResult): toTest is KsTest2SampResult { return 'ks_test_2samp' === toTest.type; }
}