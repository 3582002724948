<p>
    The Augmented Dickey-Fuller test tests the hypothesis that there exists a
    unit root in the time series <b>{{ params.seriesColumn.name }}</b>, in the
    context of serial correlation.

    Serial correlation occurs in a time series when a series value and a lagged
    version of itself (for instance the values at times T and at T-1) are
    observed to be correlated with one another over periods of time.
</p>

<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>

<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        The hypothesis is rejected. The time series <b>{{ params.seriesColumn.name }}</b>
        has no unit root. It may be considered as {{ stationarityLabel }}.
    </li>

    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. The test is inconclusive
        about whether there exists a unit root for the time series
        <b>{{ params.seriesColumn.name }}</b>.
    </li>
</ul>
