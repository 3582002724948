<table class="deephub-pair-list w100" *ngIf="pairList">
    <thead>
        <tr class="deephub-pair-list__row deephub-pair-list__row--header">
            <th width="38%">Ground Truth</th>
            <th width="38%">Prediction</th>
            <th width="17%">Score</th>
            <th width="7%"></th>
        </tr>
    </thead>
    <tbody>
        <tr class="deephub-pair-list__row deephub-pair-list__row--body" *ngFor="let pair of pairList" (mouseenter)="canvasPainter.togglePairHover(pair)" (mouseleave)="canvasPainter.togglePairHover(pair)" (click)="canvasPainter.togglePairSelection(pair)" [ngClass]="{
            'deephub-pair-list__row--selected': pair.boxGrouping.data.selected,
            'deephub-pair-list__row--hovered': pair.boxGrouping.data.hovered
        }">
            <td>
                <div class="horizontal-flex aic">
                    <div class="deephub-image-legend noflex">
                        <span class="deephub-image-legend__item" [style.background-image]="'repeating-linear-gradient(to right, ' + pair.groundTruth?.stroke + ' 0 20%, transparent 20% 25%)'"></span>
                    </div>
                    <span class="mleft4 mx-textellipsis" [class.text-prompt]="!pair.groundTruth" [title]="pair.groundTruth?.data.category || 'N/A'">
                        {{ pair.groundTruth?.data.category || 'N/A' }}
                    </span>
                </div>
            </td>
            <td>
                <div class="horizontal-flex aic">
                    <div class="deephub-image-legend noflex">
                        <span class="deephub-image-legend__item" [style.background-color]="pair.detection?.stroke || 'transparent'"></span>
                    </div>
                    <span class="mleft4 mx-textellipsis" [class.text-prompt]="!pair.detection" [title]="pair.detection?.data.category || 'N/A'" >
                        {{ pair.detection?.data.category || 'N/A' }}
                    </span>
                </div>
            </td>
            <td [class.text-prompt]="!pair.detection?.data.confidence">
                {{ (pair.detection?.data.confidence | number) || 'N/A' }}
            </td>
            <td>
                <i class="deephub-pair-list__icon" [ngClass]="{
                    'icon-remove-sign icon-error-color': pair.detection?.data.category !== pair.groundTruth?.data.category,
                    'icon-ok-sign icon-ok-color': pair.detection?.data.category === pair.groundTruth?.data.category
                }"></i>
            </td>
        </tr>
    </tbody>
</table>