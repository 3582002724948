import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CellData } from '@shared/services/images-data-fetcher.service';
import { SampleDataFetcherService } from '@shared/services/sample-data-fetcher.service';
import { DeepHubColumnFormat, SerializedMemTableV2 } from 'src/generated-sources';

export interface DeephubCellData extends CellData {
    target: DeepHubColumnFormat.ObjectDetectionTargetItem[]
}

export interface DeephubColumn {
    name: string,
    index?: number
}

export interface DeephubDataColumns {
    target: DeephubColumn,
    itemPath: DeephubColumn
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDeephubDataFetcherService extends SampleDataFetcherService {
    dataColumns: DeephubDataColumns;

    abstract createDataColumns(orderedColumns: string[]): DeephubDataColumns;

    onRefreshSample(sample: SerializedMemTableV2): void {
        this.dataColumns = this.createDataColumns(sample.allColumnNames);
        this.tableData = sample;
        this.totalItems = sample.totalKeptRows;
    }
    
    // modifies dataColumns object
    addColumnIndices(dataColumns: DeephubDataColumns, orderedColumns: string[]) {
        Object.values(dataColumns).forEach(column => {
            column.index = orderedColumns.indexOf(column.name);
        });
    }

    rowToCellData(rowContent: string[], i: number, j: number): DeephubCellData {
        return {
            itemPath: rowContent[this.dataColumns.itemPath.index!],
            target: JSON.parse(rowContent[this.dataColumns.target.index!]),
            imageId: i
        };
    }
}
