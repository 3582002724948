import { Card, isUnivariateCard, isBivariateCard, isTrivariateCard, isAbstractHeaderCard, isMultivariateCard, isAbstract2SampTestCard, isAbstractNSampTestCard, isShapiroNormalityTestCard, isTZTest1SampCard, isFit2DDistributionCard, isFitCurveCard, isPCACard, isParallelCoordinatesPlotCard, isCDFPlotCard, isFitDistributionCard, isChi2IndTestCard, isSignTest1SampCard, isWorksheetRootCard, isColumnCard, CardResult, isAbstractCardContainer, Worksheet, isCorrelationMatrixCard, Filter, isAndFilter, isAllFilter, Variable, PCACard, ParallelCoordinatesPlotCard, CDFPlotCard, ScatterPlot3DCard, isScatterPlot3DCard, isScatterPlotCard, ScatterPlotCard, isTimeSeriesCard, isUnitRootTestADFCard, isUnitRootTestZACard, isUnitRootTestKPSSCard, isUnitRootTestCard, isDurbinWatsonCard } from 'src/generated-sources';
import { FormatModalityPipe } from '@shared/pipes/list-pipes/format-modality.pipe';
import { PlurifyPipe } from '@shared/pipes/text-pipes/plurify.pipe';
import { assertNever } from 'dku-frontend-core';
import { randomId } from '@utils/random';
import _ from 'lodash';
import deepEqual from 'fast-deep-equal';
import { CapitalizePipe } from '@shared/pipes/capitalize.pipe';
import produce from 'immer';
import { CardWizardVariable } from './card-models';

const formatModality = new FormatModalityPipe().transform;
const plurify = new PlurifyPipe().transform;
const capitalize = new CapitalizePipe().transform;

// Short title (without any config-related wordings)
export function getBasicCardTitle(card: Card): string {
    switch (card.type) {
        case 'bivariate_box_plot':
            return 'Box Plot';
        case 'bivariate_frequency_table':
            return 'Frequency table';
        case 'bivariate_header':
            return 'Bivariate analysis';
        case 'bivariate_histogram':
            return 'Histogram';
        case 'bivariate_summary':
            return 'Summary';
        case 'categorical_histogram':
            return 'Histogram';
        case 'chi2_independence_test':
            return 'Chi-square independence test';
        case 'column_card':
            return 'variable';
        case 'correlation_matrix':
            return 'Correlation matrix';
        case 'fit_2d_distribution':
            return 'Fit 2D distribution';
        case 'fit_curve':
            return 'Fit curve';
        case 'fit_distribution':
            return 'Fit distribution';
        case 'ks_test_2samp':
            return 'Two-sample Kolmogorov–Smirnov test';
        case 'mood_nsamp':
            return 'N-sample Mood test';
        case 'mood_test_2samp':
            return 'Two-sample Mood test';
        case 'mosaic_plot':
            return 'Mosaic plot';
        case 'numerical_histogram':
            return card.showHistogram ? 'Histogram' : 'Box Plot';
        case 'oneway_anova':
            return 'One-way ANOVA';
        case 'pairwise_mood':
            return 'Pairwise Mood test';
        case 'pairwise_ttest':
            return 'Pairwise 𝘵-test';
        case 'pca':
            return 'PCA';
        case 'quantile_table':
            return 'Quantile table';
        case 'scatter_plot':
            return 'Scatter plot';
        case 'shapiro':
            return 'Shapiro-Wilk normality test';
        case 'sign_test_1samp':
            return 'Sign test';
        case 'ttest_ztest_1samp':
            return card.knownStdDev == null ? 'Student 𝘵-test' : 'Z-test';
        case 'ttest_2samp':
            return 'Two-sample 𝘵-test';
        case 'univariate_frequency_table':
            return 'Frequency table';
        case 'univariate_header':
            return 'Univariate analysis';
        case 'univariate_summary':
            return 'Summary stats';
        case 'worksheet_root':
            return 'Worksheet';
        case 'scatter_plot_3d':
            return "Scatter plot 3D";
        case 'cdf_plot':
            return "Cumulative distribution function";
        case 'parallel_coordinates_plot':
            return "Parallel coordinates plot";
        case 'unit_root_test_adf':
            return "Augmented Dickey-Fuller test";
        case 'unit_root_test_za':
            return "Zivot-Andrews test";
        case 'unit_root_test_kpss':
            return "Kwiatkowski-Phillips-Schmidt-Shin test";
        case 'durbin_watson':
            return "Durbin-Watson statistic";
    }
}

// Menu title in action menus
export function getConfigureCardTitle(card: Card): string {
    if (isAbstract2SampTestCard(card)
        || isAbstractNSampTestCard(card)
        || isShapiroNormalityTestCard(card)
        || isTZTest1SampCard(card)
        || isChi2IndTestCard(card)
        || isSignTest1SampCard(card)
        || isUnitRootTestCard(card)
    ) {
        return 'Configure test…';
    }
    if (isFit2DDistributionCard(card)) {
        return 'Configure distribution…';
    }
    if (isFitDistributionCard(card)) {
        return 'Configure distributions…';
    }
    if (isFitCurveCard(card)) {
        return 'Configure curves…';
    }
    if (isPCACard(card)) {
        return 'Configure PCA…';
    }
    if (isDurbinWatsonCard(card)) {
        return 'Configure Durbin-Watson…';
    }
    return 'Configure ' + getBasicCardTitle(card).toLowerCase() + '…';
}

// Full title (with config-related wordings)
export function getFullCardTitle(card: Card): string {
    let ret = getBasicCardTitle(card);

    if (!getCardSubtitle(card)) {
        if (isAbstractHeaderCard(card)) {
            ret += ` on ${card.xColumns.length} ${plurify('variable', card.xColumns.length)} `;
        } else if (isMultivariateCard(card)) {
            ret += ` on ${card.columns.length} ${plurify('variable', card.columns.length)} `;
        } else if (isUnivariateCard(card)) {
            ret += ` on ${card.column.name} `;
        } else if (isBivariateCard(card)) {
            ret += ` on ${card.xColumn.name} & ${card.yColumn.name} `;
        } else if (isDurbinWatsonCard(card)) {
            ret += ` on ${card.seriesColumn.name}`;
        }
    }

    if (isCorrelationMatrixCard(card)) {
        ret += ' (' + capitalize(card.metric!.toLowerCase()) + ')';
    }

    if (isCorrelationMatrixCard(card) && card.heatmapParams.filterVariablesWithoutValues) {
        ret += ' (hiding variables with no value)';
    }

    return ret;
}


// Sub-title (additional config-related wordings when they are not present in the full title)
export function getCardSubtitle(card: Card): string {
    if (isAbstract2SampTestCard(card)) {
        let testTarget;
        switch (card.type) {
            case 'ks_test_2samp':
                testTarget = 'distribution';
                break;
            case 'mood_test_2samp':
                testTarget = 'median';
                break;
            case 'ttest_2samp':
                testTarget = 'mean';
                break;
            default:
                assertNever(card);
        }

        if (card.value1 == null && card.value2 == null) {
            return `Compare ${testTarget} of ${card.xColumn.name} `
            + `for the two most common values `
            + `from ${card.yColumn.name}`;
        }

        return `Compare ${testTarget} of ${card.xColumn.name} `
            + `for ${formatModality(card.value1!, true)} and ${formatModality(card.value2!, true)} `
            + `from ${card.yColumn.name}`;
    }

    if (isAbstractNSampTestCard(card)) {
        let testTarget;
        switch (card.type) {
            case 'oneway_anova':
            case 'pairwise_ttest':
                testTarget = 'mean';
                break;
            case 'pairwise_mood':
            case 'mood_nsamp':
                testTarget = 'median';
                break;
            default:
                assertNever(card);
        }

        if (card.yValues) {
            return `Compare ${testTarget} of ${card.xColumn.name} `
                + `for ${formatModality(card.yValues, true)} of ${card.yColumn.name}`;
        } else {
            return `Compare ${testTarget} of ${card.xColumn.name} `
                + `for each ${card.yColumn.name}`;
        }
    }

    if (isTimeSeriesCard(card)) {
        const prefix = `Test whether ${card.seriesColumn.name}`;
        if (isUnitRootTestADFCard(card)) {
            return `${prefix} has a unit root`;
        }

        if (isUnitRootTestZACard(card)) {
            return `${prefix} has a unit root with a single structural break`;
        }

        if (isUnitRootTestKPSSCard(card)) {
            return `${prefix} is stationary`;
        }
    }

    return '';
}

/**
 * Produces a user-friendlier filter selecting the same subset of data
 *
 * Filters produced by combining other filters can be dumb. Example:
 * - Histogram card on "Sex" + split by "Survived"
 * - User clicks on "Sex: Male" bar within the "All" group
 * => The combined filter is AndFilter(AnumFilter('Sex','Male'), AllFilter())
 * => The simplified filter is just AnumFilter('Sex','Male')
 */
function simplifyFilter(filter: Filter): Filter {
    if (isAndFilter(filter)) {
        const innerFilters = [];
        for (const innerFilter of filter.filters) {
            const simplifiedInnerFilter = simplifyFilter(innerFilter);
            if (isAndFilter(simplifiedInnerFilter)) {
                for (const innerOfSimplified of simplifiedInnerFilter.filters) {
                    innerFilters.push(innerOfSimplified);
                }
            } else {
                innerFilters.push(simplifiedInnerFilter);
            }
        }

        const dedupedFilters = [];
        outer: for (const innerFilter of innerFilters) {
            for (const dedupedFilter of dedupedFilters) {
                if (deepEqual(dedupedFilter, innerFilter)) {
                    continue outer;
                }
            }
            if (!isAllFilter(innerFilter)) {
                dedupedFilters.push(innerFilter);
            }
        }

        if (dedupedFilters.length === 0) {
            return { type: 'all' };
        } else if (dedupedFilters.length === 1) {
            return dedupedFilters[0];
        } else {
            return { type: 'and', filters: dedupedFilters }
        }
    }
    return filter;
}

/**
 * Return a simplified filter, or null if the filter doesn't filter anything
 */
export function noFilterIfAll(filter?: Filter | null): Filter | null {
    if (!filter) {
        return null;
    }
    filter = simplifyFilter(filter);
    if (filter.type === 'all') {
        return null;
    }
    return filter;
}

export function getNumberOfColumns(card: Card): number {
    if (isAbstractHeaderCard(card)) {
        return card.xColumns.length;
    } else if (isMultivariateCard(card)) {
        return card.columns.length;
    } else if (isUnivariateCard(card)) {
        return 1;
    } else if (isBivariateCard(card)) {
        return 2;
    } else if (isTimeSeriesCard(card)) {
        return 2;
    } else if (isTrivariateCard(card)) {
        return 3;
    } else if (isWorksheetRootCard(card) || isColumnCard(card)) {
        return 0;
    }

    return assertNever(card);
}

export const MAX_HEADER_COLUMNS = 100;

export const CANNOT_ADD_REASON = {
    MAX_VARIABLES_EXCEEDED: `Number of variables cannot exceed ${MAX_HEADER_COLUMNS}.`,
    NO_VARIABLE_SELECTED: 'No variables are selected.',
    NOT_EXACTLY_ONE: `Exactly 1 variable must be selected.`
};


export function isFullyComputed(result: CardResult) {
    if (result.type === 'unavailable') {
        return result.reason !== CardResult.UnavailabilityReason.NOT_COMPUTED;
    }
    return !(result.computationCount > 0 && result.notComputedCount > 0);
}

// Set all the card IDs to `null` (recursively).
// Useful for comparing cards using deep equality.
export function clearCardIds<T extends Card>(card: T): T {
    if (isAbstractCardContainer(card)) {
        card = {
            ...card,
            cards: card.cards.map(subCard => clearCardIds(subCard))
        };
    }
    return { ...card, id: null };
}

// Re-generate new card IDs (recursively)
export function resetCardIds<T extends Card>(card: T): T {
    if (isAbstractCardContainer(card)) {
        card = {
            ...card,
            cards: card.cards.map(subCard => resetCardIds(subCard))
        };
    }
    return { ...card, id: randomId() };
}

// WT1 tracking purpose
export function getWorksheetStats(worksheet: Worksheet) {
    return {
        sample: {
            ..._.pick(worksheet.dataSpec.datasetSelection, ['samplingMethod', 'maxRecords', 'targetRatio']),
            hasFilter: worksheet.dataSpec.datasetSelection.filter && worksheet.dataSpec.datasetSelection.filter.enabled
        },
        cards: worksheet.rootCard ? worksheet.rootCard.cards.map(card => {
            return {
                type: card.type,
                split: card.splitBy !== null,
                nbColumns: getNumberOfColumns(card),
            };
        }) : []
    };
}

export function identicalVariableNames(a: Variable, b: Variable) {
    return a.name === b.name;
}


export function unselectVariables(vars: CardWizardVariable[]): CardWizardVariable[] {
    return produce(vars, draft => {
        draft.map(v => {
            if (Object.keys(v)) {
                v.selected = false;
            }
            return v;
        })
    });
}

export function unselectVariable(variable: CardWizardVariable): CardWizardVariable {
    return produce(variable, draft => {
        if (Object.keys(draft).length) {
            draft.selected = false;
        }
    });
}

export function enableVariables(vars: CardWizardVariable[]): CardWizardVariable[] {
    return produce(vars, draft => {
        draft.map(v => {
            if (Object.keys(v)) {
                v.disabled = false;
            }
            return v;
        });
    });
}

type CardWithColorBy =
    CDFPlotCard |
    PCACard |
    ParallelCoordinatesPlotCard |
    ScatterPlot3DCard |
    ScatterPlotCard;

export function acceptsColoring(card: Card): card is CardWithColorBy {
    return isPCACard(card)
        || isParallelCoordinatesPlotCard(card)
        || isCDFPlotCard(card)
        || isScatterPlot3DCard(card)
        || isScatterPlotCard(card);
}
