import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UnitRootTestKPSS, UnitRootTestKPSSCard } from "src/generated-sources";

@Component({
    selector: 'unit-root-test-kpss-card-help',
    templateUrl: './unit-root-test-kpss-card-help.component.html',
    styleUrls: ['./unit-root-test-kpss-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitRootTestKPSSCardHelpComponent {
    @Input() params: UnitRootTestKPSSCard;

    get stationarityLabel(): string {
        switch (this.params.regressionMode) {
            case UnitRootTestKPSS.RegressionMode.CONSTANT:
                return "stationary";

            case UnitRootTestKPSS.RegressionMode.CONSTANT_WITH_TREND:
                return "trend-stationary";
        }
    }
}
