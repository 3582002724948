import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {_AvailableResult} from './../available-result';
import {_TimeSeriesComputation} from './time-series-computation';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson
 */
export interface DurbinWatson extends _TimeSeriesComputation {
    // PolyJson type
    type: 'durbin_watson';
}

export function isDurbinWatson(toTest: Computation): toTest is DurbinWatson { return 'durbin_watson' === toTest.type; }

export namespace DurbinWatson {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.DurbinWatson$DurbinWatsonResult
     */
    export interface DurbinWatsonResult extends _AvailableResult {
        maxTimestamp: string;
        minTimestamp: string;
        statistic: number;

        // PolyJson type
        type: 'durbin_watson';
    }

    export function isDurbinWatsonResult(toTest: ComputationResult): toTest is DurbinWatsonResult { return 'durbin_watson' === toTest.type; }
}