import {Chi2IndTest} from './bivariate/chi2-ind-test';
import {CountGroups} from './common/count-groups';
import {Count} from './common/count';
import {Covariance} from './bivariate/covariance';
import {DummyComputation} from './common/dummy-computation';
import {DurbinWatson} from './timeseries/durbin-watson';
import {EmpiricalCDF} from './univariate/empirical-cdf';
import {Entropy} from './univariate/entropy';
import {FetchValues} from './multivariate/fetch-values';
import {Fit2DDistribution} from './bivariate/fit2-ddistribution';
import {FitCurve} from './bivariate/fit-curve';
import {FitDistribution} from './univariate/fit-distribution';
import {GroupedComputation} from './common/grouped-computation';
import {KendallTau} from './bivariate/kendall-tau';
import {KsTest2Samp} from './univariate/ks-test2-samp';
import {Kurtosis} from './univariate/kurtosis';
import {Max} from './univariate/max';
import {Mean} from './univariate/mean';
import {Min} from './univariate/min';
import {MoodTestNSamp} from './univariate/mood-test-nsamp';
import {MultiComputation} from './common/multi-computation';
import {MutualInformation} from './bivariate/mutual-information';
import {OneWayAnova} from './univariate/one-way-anova';
import {PCA} from './multivariate/pca';
import {PairwiseMoodTest} from './univariate/pairwise-mood-test';
import {PairwiseTTest} from './univariate/pairwise-ttest';
import {Pearson} from './bivariate/pearson';
import {Quantiles} from './univariate/quantiles';
import {Sem} from './univariate/sem';
import {Shapiro} from './univariate/shapiro';
import {SignTest1Samp} from './univariate/sign-test1-samp';
import {Skewness} from './univariate/skewness';
import {Spearman} from './bivariate/spearman';
import {StdDev} from './univariate/std-dev';
import {Sum} from './univariate/sum';
import {TTest1Samp} from './univariate/ttest1-samp';
import {TestDistribution} from './univariate/test-distribution';
import {UnitRootTestADF} from './timeseries/unit-root-test-adf';
import {UnitRootTestKPSS} from './timeseries/unit-root-test-kpss';
import {UnitRootTestZA} from './timeseries/unit-root-test-za';
import {Variance} from './univariate/variance';
import {ZTest1Samp} from './univariate/ztest1-samp';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.Computation
 */
export interface _Computation {}

export type Computation = CountGroups | Quantiles | Chi2IndTest | EmpiricalCDF | Kurtosis | Spearman | Covariance | FetchValues | Min | PairwiseMoodTest | KsTest2Samp | UnitRootTestKPSS | PCA | Fit2DDistribution | TTest1Samp | Pearson | Sem | UnitRootTestZA | DummyComputation | MoodTestNSamp | FitDistribution | Mean | Skewness | TestDistribution | DurbinWatson | Sum | KendallTau | GroupedComputation | Shapiro | Count | StdDev | MultiComputation | UnitRootTestADF | PairwiseTTest | Max | SignTest1Samp | Entropy | Variance | FitCurve | ZTest1Samp | OneWayAnova | MutualInformation;

export namespace Computation {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$UnivariateComputation
     */
    export interface _UnivariateComputation extends _Computation {
        column: string;
    }

    export type UnivariateComputation = Sem | Quantiles | EmpiricalCDF | MoodTestNSamp | Kurtosis | Min | PairwiseMoodTest | FitDistribution | Mean | Sum | Skewness | TestDistribution | KsTest2Samp | Shapiro | StdDev | PairwiseTTest | Max | SignTest1Samp | Entropy | Variance | ZTest1Samp | OneWayAnova | TTest1Samp;

    export function isUnivariateComputation(toTest: Computation): toTest is UnivariateComputation { return ["sem" , "quantiles" , "empirical_cdf" , "mood_test_nsamp" , "kurtosis" , "min" , "pairwise_mood_test" , "fit_distribution" , "mean" , "sum" , "skewness" , "test_distribution" , "ks_test_2samp" , "shapiro" , "std_dev" , "pairwise_ttest" , "max" , "sign_test_1samp" , "entropy" , "variance" , "ztest_1samp" , "one_way_anova" , "ttest_1samp"].includes(toTest.type); }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$BivariateComputation
     */
    export interface _BivariateComputation extends _Computation {
        xColumn: string;
        yColumn: string;
    }

    export type BivariateComputation = Pearson | KendallTau | Fit2DDistribution | Spearman | Covariance | FitCurve | MutualInformation;

    export function isBivariateComputation(toTest: Computation): toTest is BivariateComputation { return ["pearson" , "kendall_tau" , "fit_2d_distribution" , "spearman" , "covariance" , "fit_curve" , "mutual_information"].includes(toTest.type); }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.Computation$MultivariateComputation
     */
    export interface _MultivariateComputation extends _Computation {
        columns: string[];
    }

    export type MultivariateComputation = PCA | FetchValues;

    export function isMultivariateComputation(toTest: Computation): toTest is MultivariateComputation { return ["pca" , "fetch_values"].includes(toTest.type); }
}