<form
    [formGroup]="configForm"
    id="config-form"
    class="dkuform-modal-horizontal"
>
    <div class="control-group">
        <label class="control-label">
            Series values
        </label>
        <single-variable-selector
            [variables]="seriesVariables$ | async"
            formControlName="seriesColumn"
        ></single-variable-selector>
    </div>

    <div class="control-group">
        <label class="control-label">
            Series ordering
        </label>
        <single-variable-selector
            [variables]="allVariables$ | async"
            formControlName="timeColumn"
        ></single-variable-selector>
    </div>

    <div class="control-group" *ngIf="isADFCard">
        <label class="control-label">
            Regression mode
        </label>
        <dku-bs-select-form-control
            [list]="adfRegressionOptions"
            formControlName="regressionMode"
            ngOptions="v.value as v.name for v in list"
        ></dku-bs-select-form-control>
    </div>

    <div class="control-group" *ngIf="isZACard">
        <label class="control-label">
            Regression mode
        </label>
        <dku-bs-select-form-control
            [list]="zaRegressionOptions"
            formControlName="regressionMode"
            ngOptions="v.value as v.name for v in list"
        ></dku-bs-select-form-control>
    </div>

    <div class="control-group" *ngIf="isKPSSCard">
        <label class="control-label">
            Regression mode
        </label>
        <dku-bs-select-form-control
            [list]="kpssRegressionOptions"
            formControlName="regressionMode"
            ngOptions="v.value as v.name for v in list"
        ></dku-bs-select-form-control>
    </div>
</form>
