import {AlphanumFacetWithMap} from './../facet/alphanum-facet-with-map';
import {DataService} from './data-service';
import {NumericalVariableAnalysis} from './../analysis/numerical-variable-analysis';
import {SampleMetadata} from './sample-metadata';
import {SchemaColumn} from './../../../../../../../../dss-core/src/main/java/com/dataiku/dip/coremodel/schema-column';
import {SerializedShakerScript} from './../model/serialized-shaker-script';
import {SerializedTableChunk} from './serialized-table-chunk';

/**
 * Generated from com.dataiku.dip.shaker.server.SerializedMemTableV2
 */
export interface SerializedMemTableV2 {
    allColumnNames: string[];
    coloringScheme: SerializedShakerScript.TableColoringScheme;
    filterFacets: any /* TODO: Missing translation of com.dataiku.dip.pivot.backend.model.FilterFacet */[];
    headers: SerializedMemTableV2.Header[];
    initialChunk: SerializedTableChunk;
    initialCols: number;
    initialRows: number;
    newColumnsSelection: SerializedShakerScript.DisplayedColumnsSelection;
    newRecipeSchema: DataService.ShakerRecipeSchema;
    previewRowsAdded: number;
    previewRowsDeleted: number;
    sampleBuildDate: number;
    sampleMetadata: SampleMetadata;
    scriptChange: any /* TODO: Missing translation of com.dataiku.dip.datalayer.memimpl.MemTable$MemTableChange */;
    totalDeletedKeptRows: number;
    totalDeletedRows: number;
    totalEmptyCells: number;
    totalFullCells: number;
    totalKeptRows: number;
    totalRows: number;
    usedSampleId: string;
    warnings: any /* TODO: Missing translation of com.dataiku.dip.warnings.WarningsContext$SerializedWarnings */;
}

export namespace SerializedMemTableV2.Header {
    /**
     * Generated from com.dataiku.dip.shaker.server.SerializedMemTableV2$Header$ProgressBarChunk
     */
    export interface ProgressBarChunk {
        cls: string;
        lower: number;
        ratio: number;
        upper: number;
        value: string;
    }

    /**
     * Generated from com.dataiku.dip.shaker.server.SerializedMemTableV2$Header$ProgressBarType
     */
    export enum ProgressBarType {
        MEANING_VALIDITY = 'MEANING_VALIDITY',
        MEANING_VALIDITY_MUTED = 'MEANING_VALIDITY_MUTED',
        NONE = 'NONE',
        VALUE_COLORING_ANUM = 'VALUE_COLORING_ANUM',
        VALUE_COLORING_NUM = 'VALUE_COLORING_NUM'
    }
}

export namespace SerializedMemTableV2 {
    /**
     * Generated from com.dataiku.dip.shaker.server.SerializedMemTableV2$MeaningResult
     */
    export interface MeaningResult {
        name: string;
        nbEmpty: number;
        nbNOK: number;
        nbOK: number;
        score: number;
    }

    /**
     * Generated from com.dataiku.dip.shaker.server.SerializedMemTableV2$Header
     */
    export interface Header {
        analysisForColoring: NumericalVariableAnalysis;
        datasetSchemaColumn: SchemaColumn;
        deletedMeaningName?: string | null;
        distinctValues: number;
        facetForColoring: AlphanumFacetWithMap;
        isDouble: boolean;
        meaningOrigin: any /* TODO: Missing translation of com.dataiku.dip.datalayer.memimpl.MemColumn$MeaningOrigin */;
        name: string;
        ncharsToShow: number;
        possibleTypes: SerializedMemTableV2.MeaningResult[];
        progressBarChunks: SerializedMemTableV2.Header.ProgressBarChunk[];
        progressBarType: SerializedMemTableV2.Header.ProgressBarType;
        recipeSchemaColumn: DataService.ShakerRecipeSchemaColumn;
        selectedType: SerializedMemTableV2.MeaningResult;
        state: string;
    }
}