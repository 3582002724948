import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {UnitRootTest, _UnitRootTest} from './unit-root-test';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF
 */
export interface UnitRootTestADF extends _UnitRootTest {
    regressionMode: UnitRootTestADF.RegressionMode;

    // PolyJson type
    type: 'unit_root_adf';
}

export function isUnitRootTestADF(toTest: Computation): toTest is UnitRootTestADF { return 'unit_root_adf' === toTest.type; }

export namespace UnitRootTestADF {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF$UnitRootTestADFResult
     */
    export interface UnitRootTestADFResult extends UnitRootTest._UnitRootTestResult {
        // PolyJson type
        type: 'unit_root_adf';
    }

    export function isUnitRootTestADFResult(toTest: ComputationResult): toTest is UnitRootTestADFResult { return 'unit_root_adf' === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestADF$RegressionMode
     */
    export enum RegressionMode {
        CONSTANT_ONLY = 'CONSTANT_ONLY',
        CONSTANT_WITH_LINEAR_TREND = 'CONSTANT_WITH_LINEAR_TREND',
        CONSTANT_WITH_LINEAR_QUADRATIC_TREND = 'CONSTANT_WITH_LINEAR_QUADRATIC_TREND',
        NO_CONSTANT_NO_TREND = 'NO_CONSTANT_NO_TREND'
    }
}