<div [style.--workspace-color]="workspace.color">
    <div class="control-group">
        <label for="displayName" class="control-label">Name</label>
        <div class="controls">
            <input type="text" placeholder="Workspace name" name="displayName"
                [(ngModel)]="workspace.displayName"
                (ngModelChange)="onDisplayNameChange()"
                required>
        </div>
    </div>

    <div class="control-group">
        <div class="controls workspace-general-edition__color-options">
            <div class="workspace-general-edition__badge-preview">
                <span class="workspace-general-edition__badge-preview-text" [textContent]="initials"></span>
            </div>
            <div class="color-options mleft8">
                <div class="color-options__list horizontal-flex">
                    <span *ngFor="let color of stockColors" 
                        class="color-dot" 
                        [class.selected]="color === workspace.color"
                        (click)="pickStockColor(color)"
                        [style.backgroundColor]="color">
                    </span>
                </div>
                <label class="workspace-general-edition__color-input">
                    <span class="color-dot">
                        <i class="icon-tint workspace-general-edition__icon-tint"></i>
                    </span>
                    <span class="color-code">{{ workspace.color }}</span>
                    <input type="color" name="workspaceColor" [(ngModel)]="workspace.color" (ngModelChange)="onWorkspaceChange()">
                </label>
            </div>
        </div>
    </div>

    <div class="control-group" *ngIf="!editionMode">
        <label for="workspaceKey" class="control-label">Workspace Key</label>
        <div class="controls">
            <input type="text"
                name="workspaceKey"
                [(ngModel)]="workspace.workspaceKey"
                (ngModelChange)="onWorkspaceChange()"
                pattern="/^\w+$/"
                placeholder="Workspace key"
                required>
        </div>
    </div>

    <div class="control-group workspace-general-edition__description">
        <label for="description" class="control-label">Description</label>
        <div class="controls">
            <autocompletable-textarea
                [(model)]="workspace.description"
                (modelChange)="onWorkspaceChange()"
                [textarea]="{ name: 'description', placeholder: 'Workspace description' }"
                options="{ autofocus: false }">
            </autocompletable-textarea>
        </div>
    </div>
</div>
