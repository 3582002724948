
<nav class="workspaces-left-menu">
    <ul class="workspaces-left-menu__list">
        <li *ngFor="let workspace of workspaces; trackBy: trackWorkspace"
            toggle="tooltip"
            container="body"
            [title]="workspace.displayName"
            class="workspaces-left-menu__object"
            [class.workspaces-left-menu__object--selected]="workspace.workspaceKey === selectedWorkspaceKey"
            (click)="selectWorkspace(workspace.workspaceKey)">
            <span class="workspaces-left-menu__object-content" [style.backgroundColor]="workspace.color">
                <span [style.color]="workspace.color" class="workspaces-left-menu__object-content-text">{{ workspace | map:mapInitials:this }}</span>
            </span>
        </li>
    </ul>
    <button class="btn btn--icon btn--primary btn--text workspaces-left-menu__add-button"
        toggle="tooltip"
        container="body"
        title="Create a new workspace"
        *ngIf="canCreateWorkspaces"
        (click)="onAddWorkspace()">
            <i class="icon-plus-sign"></i>
    </button>
</nav>
