import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WidgetsModule } from "@app/widgets/widgets.module";
import { ComponentsModule } from "@shared/components/components.module";
import { DeephubObjectDetectionDesignImageFeedComponent } from "./object-detection/design/image-feed/deephub-object-detection-design-image-feed.component";
import { BrowserModule } from "@angular/platform-browser";
import { DeephubImageDetailsComponent } from './shared-components/deephub-image-details/deephub-image-details.component';
import { PipesModule } from "@shared/pipes/pipes.module";
import { DeephubObjectDetectionReportComponent } from './object-detection/report/deephub-object-detection-report.component';
import { DeephubObjectDetectionReportPrecisionRecallChartComponent } from './object-detection/report/precision-recall-chart/deephub-object-detection-report-precision-recall-chart.component';
import { DeephubObjectDetectionReportConfusionMatrixComponent } from './object-detection/report/confusion-matrix/deephub-object-detection-report-confusion-matrix.component';
import { DeephubObjectDetectionReportImageFeedComponent } from './object-detection/report/image-feed/deephub-object-detection-report-image-feed.component';
import { DeephubObjectDetectionReportImageFeedModalComponent } from './object-detection/report/image-feed-modal/deephub-object-detection-report-image-feed-modal.component';
import { DeephubObjectDetectionReportImageLegendComponent } from './object-detection/report/image-legend/deephub-object-detection-report-image-legend.component';
import { NgxEchartsModule } from "ngx-echarts";
import { DeephubObjectDetectionReportPairListComponent } from './object-detection/report/pair-list/deephub-object-detection-report-pair-list.component';
import { DeephubObjectDetectionDesignImageFeedModalComponent } from './object-detection/design/image-feed-modal/deephub-object-detection-design-image-feed-modal.component';

@NgModule({
    declarations: [
        DeephubObjectDetectionDesignImageFeedComponent,
        DeephubImageDetailsComponent,
        DeephubObjectDetectionReportComponent,
        DeephubObjectDetectionReportPrecisionRecallChartComponent,
        DeephubObjectDetectionReportConfusionMatrixComponent,
        DeephubObjectDetectionReportImageFeedComponent,
        DeephubObjectDetectionReportImageFeedModalComponent,
        DeephubObjectDetectionReportImageLegendComponent,
        DeephubObjectDetectionReportPairListComponent,
        DeephubObjectDetectionDesignImageFeedModalComponent,
    ],
    exports: [],
    entryComponents: [
        DeephubObjectDetectionDesignImageFeedModalComponent,
        DeephubObjectDetectionReportImageFeedModalComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        WidgetsModule,
        PipesModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ]
})

export class DeephubModule {}