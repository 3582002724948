import { Injectable } from "@angular/core";
import { Chunk } from "@shared/components/infinite-scroll/infinite-scroll.component";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { SerializedMemTableV2, SerializedTableChunk } from "src/generated-sources";
import { ImagesDataFetcherService } from "./images-data-fetcher.service";

@Injectable()
export abstract class SampleDataFetcherService extends ImagesDataFetcherService {
    totalItems: number | null;
    tableData: SerializedMemTableV2 | undefined;

    abstract refreshSample(): Observable<SerializedMemTableV2>;

    abstract getSampleChunk(offset: number, nbRows: number): Observable<SerializedTableChunk>;

    getChunk(offset: number): Observable<Chunk> {
        let chunk$: Observable<SerializedTableChunk>;

        if (this.totalItems !== null && this.totalItems > 0 && offset >= this.totalItems) {
            return of({
                totalItems: this.totalItems,
                chunkItems: []
            });
        }

        if (offset === 0) {
            chunk$ = this.refreshSample()
                .pipe(
                    map(result => result.initialChunk)
                );
        } else {
            chunk$ = this.getSampleChunk(offset, this.CHUNK_SIZE);
        }

        return chunk$.pipe(
            map(chunk => ({
                totalItems: chunk.totalKeptRows,
                chunkItems: Array.from(Array(Math.min(chunk.nbRows, chunk.totalKeptRows - offset)))
                .map((row, index) => {
                    const rowContent = chunk.content.slice(index * chunk.nbCols, (index + 1) * chunk.nbCols);
                    return this.rowToCellData(rowContent, offset + index, 0);
                })
            }))
        );
    };
}
