import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CardWizardVariable } from "@features/eda/card-models";
import { CardWizardService } from "@features/eda/worksheet/card-wizard/card-wizard.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable } from "rxjs";
import { isUnitRootTestADFCard, isUnitRootTestKPSSCard, isUnitRootTestZACard, TimeSeriesCard, UnitRootTestADF, UnitRootTestADFCard, UnitRootTestKPSS, UnitRootTestKPSSCard, UnitRootTestZA, UnitRootTestZACard } from "src/generated-sources";

@UntilDestroy()
@Component({
    selector: 'time-series-card-config',
    templateUrl: './time-series-card-config.component.html',
    styleUrls: [
        './time-series-card-config.component.less',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSeriesCardConfigComponent implements OnInit, OnChanges, OnDestroy {
    @Input() params: TimeSeriesCard;
    @Output() paramsChange = new EventEmitter<TimeSeriesCard>(true);
    @Output() validityChange = new EventEmitter<boolean>(true);

    configForm: FormGroup;
    allVariables$: Observable<CardWizardVariable[]>;
    seriesVariables$: Observable<CardWizardVariable[]>;

    constructor(
        private fb: FormBuilder,
        private cardWizardService: CardWizardService,
    ) {
        this.configForm = this.fb.group({
            seriesColumn: this.fb.control(null, [
                Validators.required,
            ]),
            timeColumn: this.fb.control(null, [
                Validators.required,
            ]),
            regressionMode: this.fb.control(null, [
                Validators.required,
            ]),
        });

        this.configForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(formValue => {
                const newCard: TimeSeriesCard = {
                    ...this.params,
                    seriesColumn: formValue.seriesColumn,
                    timeColumn: formValue.timeColumn,
                };

                if (isUnitRootTestADFCard(newCard)) {
                    (newCard as UnitRootTestADFCard).regressionMode = formValue.regressionMode;

                } else if (isUnitRootTestZACard(newCard)) {
                    (newCard as UnitRootTestZACard).regressionMode = formValue.regressionMode;

                } else if (isUnitRootTestKPSSCard(newCard)) {
                    (newCard as UnitRootTestKPSSCard).regressionMode = formValue.regressionMode;
                }

                this.paramsChange.emit(newCard);
        });

        this.configForm.statusChanges
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.validityChange.emit(this.configForm.valid);
            });
    }

    ngOnInit(): void {
        this.allVariables$ = this.cardWizardService.availableVariables(this.params.type);
        this.seriesVariables$ = this.cardWizardService.availableVariables(this.params.type, { isSeriesVariable: true })
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.params == null) {
            return;
        }

        const regressionModeControl = this.configForm.get('regressionMode')!;
        const card = this.params;
        const patch = {
            seriesColumn: card.seriesColumn,
            timeColumn: card.timeColumn,
        };

        if (isUnitRootTestADFCard(card)) {
            regressionModeControl.enable();
            (patch as UnitRootTestADFCard).regressionMode = card.regressionMode;

        } else if (isUnitRootTestZACard(card)) {
            regressionModeControl.enable();
            (patch as UnitRootTestZACard).regressionMode = card.regressionMode;

        } else if (isUnitRootTestKPSSCard(card)) {
            regressionModeControl.enable();
            (patch as UnitRootTestKPSSCard).regressionMode = card.regressionMode;

        } else {
            regressionModeControl.disable();
        }

        this.configForm.patchValue(patch, { emitEvent: true });
    }

    ngOnDestroy(): void {
        // required by @UntilDestroy
    }

    get isADFCard(): boolean {
        return isUnitRootTestADFCard(this.params);
    }

    get isZACard(): boolean {
        return isUnitRootTestZACard(this.params);
    }

    get isKPSSCard(): boolean {
        return isUnitRootTestKPSSCard(this.params);
    }

    adfRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestADF.RegressionMode.CONSTANT_ONLY,
        },
        {
            name: 'Constant, linear trend',
            value: UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_TREND,
        },
        {
            name: 'Constant, linear and quadratic trend',
            value: UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_QUADRATIC_TREND,
        },
        {
            name: 'No constant, no trend',
            value: UnitRootTestADF.RegressionMode.NO_CONSTANT_NO_TREND,
        },
    ];

    zaRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestZA.RegressionMode.CONSTANT_ONLY,
        },
        {
            name: 'Trend only',
            value: UnitRootTestZA.RegressionMode.TREND_ONLY,
        },
        {
            name: 'Constant with trend',
            value: UnitRootTestZA.RegressionMode.CONSTANT_WITH_TREND,
        },
    ];

    kpssRegressionOptions = [
        {
            name: 'Constant only',
            value: UnitRootTestKPSS.RegressionMode.CONSTANT,
        },
        {
            name: 'Constant with trend',
            value: UnitRootTestKPSS.RegressionMode.CONSTANT_WITH_TREND,
        },
    ];
}
