import {BoxPlotFragment} from './fragments/box-plot-fragment';
import {Card, _Card} from './card';
import {CardResult, _CardResult} from './card-result';
import {DurbinWatsonCard} from './durbin-watson-card';
import {UnitRootTestADFCard} from './unit-root-test-adfcard';
import {UnitRootTestKPSSCard} from './unit-root-test-kpsscard';
import {UnitRootTestZACard} from './unit-root-test-zacard';
import {Variable} from './../models/variable';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard
 */
export interface _TimeSeriesCard extends _Card {
    seriesColumn: Variable;
    timeColumn: Variable;
}

export type TimeSeriesCard = UnitRootTestADFCard | UnitRootTestKPSSCard | DurbinWatsonCard | UnitRootTestZACard;

export function isTimeSeriesCard(toTest: Card): toTest is TimeSeriesCard { return ["unit_root_test_adf" , "unit_root_test_kpss" , "durbin_watson" , "unit_root_test_za"].includes(toTest.type); }

export namespace TimeSeriesCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesCardResult
     */
    export interface _TimeSeriesCardResult extends _CardResult {
        boxPlot: BoxPlotFragment;
        timeInfo: TimeSeriesCard.TimeSeriesCardResult.TimeInfo;
    }

    export type TimeSeriesCardResult = UnitRootTestADFCard.UnitRootTestADFCardResult | DurbinWatsonCard.DurbinWatsonCardResult | UnitRootTestKPSSCard.UnitRootTestKPSSCardResult | UnitRootTestZACard.UnitRootTestZACardResult;

    export function isTimeSeriesCardResult(toTest: CardResult): toTest is TimeSeriesCardResult { return ["unit_root_test_adf" , "durbin_watson" , "unit_root_test_kpss" , "unit_root_test_za"].includes(toTest.type); }
}

export namespace TimeSeriesCard.TimeSeriesCardResult {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.TimeSeriesCard$TimeSeriesCardResult$TimeInfo
     */
    export interface TimeInfo {
        max: string;
        min: string;
    }
}