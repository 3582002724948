<div class="card-layout">
    <div class="card-layout__section">

        <div class="card-layout__section">
            <div class="card-layout__title">Summary</div>
            <div class="card-layout__content">
                <div class="card-layout__section card-layout__section--horizontal">
                    <div class="card-layout__section">
                        <div class="card-layout__content">
                            <table class="stats-table">
                                <tbody class="stats-table__body">
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Count</th>
                                        <td class="stats-table__cell">{{ results.boxPlot.countComplete }}</td>
                                    </tr>
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Series mean</th>
                                        <td class="stats-table__cell">{{ results.boxPlot.mean | smarterNumber }}</td>
                                    </tr>
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Series standard deviation</th>
                                        <td class="stats-table__cell">{{ results.boxPlot.stdDev | smarterNumber }}</td>
                                    </tr>
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Series box plot</th>
                                        <td class="stats-table__cell boxplot-cell">
                                            <embedded-box-plot
                                                [variableName]="params.seriesColumn.name"
                                                [boxPlot]="results.boxPlot"
                                            ></embedded-box-plot>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-layout__section">
                        <div class="card-layout__content">
                            <table class="stats-table">
                                <tbody class="stats-table__body">
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Series ordering</th>
                                        <td class="stats-table__cell">{{ params.timeColumn.name }}</td>
                                    </tr>
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Min timestamp</th>
                                        <td class="stats-table__cell timestamp-cell">{{ results.timeInfo.min }}</td>
                                    </tr>
                                    <tr class="stats-table__row">
                                        <th class="stats-table__head">Max timestamp</th>
                                        <td class="stats-table__cell timestamp-cell">{{ results.timeInfo.max }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-layout__section">
            <div class="card-layout__title">Results</div>
            <div class="card-layout__content">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Statistic</th>
                            <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Interval for positive serial correlation</th>
                            <td class="stats-table__cell">&le; 1.5</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Interval for negative serial correlation</th>
                            <td class="stats-table__cell">&ge; 2.5</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<div class="test-conclusion">
    <p *ngIf="hasLikelyPositiveCorrelation"
       class="test-conclusion__wording test-conclusion__wording--rejected"
    >
        A statistic of {{ results.statistic | smarterNumber }} (&le; 1.5)
        shows evidence of positive serial correlation in the series
        <u>{{ params.seriesColumn.name }}</u>.
    </p>
    <p *ngIf="hasLikelyNegativeCorrelation"
       class="test-conclusion__wording test-conclusion__wording--rejected"
    >
        A statistic of {{ results.statistic | smarterNumber }} (&ge; 2.5)
        shows evidence of negative serial correlation in the series
        <u>{{ params.seriesColumn.name }}</u>.
    </p>
    <p *ngIf="isInconclusive"
       class="test-conclusion__wording"
    >
        A statistic off {{ results.statistic | smarterNumber }} does not
        show enough evidence for serial correlation in the series
        <u>{{ params.seriesColumn.name }}</u>.
    </p>
</div>
