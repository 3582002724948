<p>
    The Kwiatkowski-Phillips-Schmidt-Shin test tests the hypothesis that the
    time series <b>{{ params.seriesColumn.name }}</b> is {{ stationarityLabel }}.
</p>

<p>
    There are two possible outcomes depending on the 𝘱-value:
</p>

<ul>
    <li>
        <b>
            𝘱-value &le; significance level (&le; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        The hypothesis is rejected. The time series
        <b>{{ params.seriesColumn.name }}</b> is not
        {{ stationarityLabel }} (it has a unit root).
    </li>

    <li>
        <b>
            𝘱-value is &gt; significance level (&gt; {{ 1 - params.confidenceLevel | smarterNumber }})
        </b>

        <br>
        Not enough evidence against the hypothesis. Test is inconclusive about
        whether the time series <b>{{ params.seriesColumn.name }}</b> is
        {{ stationarityLabel }}.
    </li>
</ul>
