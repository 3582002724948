import { Injectable } from '@angular/core';
import { BasePainterOptions, ImagePositionInformation } from '@shared/models/painter';
import { PainterService } from '@shared/services/painter.service';
import { DeepHubColumnFormat } from 'src/generated-sources';

export enum BoxType {
    GROUND_TRUTH = 'groundTruth',
    DETECTION = 'detection'
}

export enum EnrichedType {
    FILTERED = 'filtered',
    VALID = 'valid'
}

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDeephubObjectDetectionReportPainterService<T> extends PainterService<T> {
    protected groundTruth: string | undefined;
    protected predicted: string | undefined;

    public setClasses(groundTruth: string | undefined, predicted: string | undefined) {
        this.groundTruth = groundTruth;
        this.predicted = predicted;
    }

    protected abstract drawBoxesForEnriched(base: T, imagePosition: ImagePositionInformation, enriched: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[], type: string, options?: BasePainterOptions): void 
}
