import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {UnitRootTest, _UnitRootTest} from './unit-root-test';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA
 */
export interface UnitRootTestZA extends _UnitRootTest {
    regressionMode: UnitRootTestZA.RegressionMode;

    // PolyJson type
    type: 'unit_root_za';
}

export function isUnitRootTestZA(toTest: Computation): toTest is UnitRootTestZA { return 'unit_root_za' === toTest.type; }

export namespace UnitRootTestZA {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA$UnitRootTestZAResult
     */
    export interface UnitRootTestZAResult extends UnitRootTest._UnitRootTestResult {
        // PolyJson type
        type: 'unit_root_za';
    }

    export function isUnitRootTestZAResult(toTest: ComputationResult): toTest is UnitRootTestZAResult { return 'unit_root_za' === toTest.type; }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestZA$RegressionMode
     */
    export enum RegressionMode {
        CONSTANT_ONLY = 'CONSTANT_ONLY',
        TREND_ONLY = 'TREND_ONLY',
        CONSTANT_WITH_TREND = 'CONSTANT_WITH_TREND'
    }
}