import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UnitRootTestADF, UnitRootTestADFCard } from "src/generated-sources";

@Component({
    selector: 'unit-root-test-adf-card-help',
    templateUrl: './unit-root-test-adf-card-help.component.html',
    styleUrls: ['./unit-root-test-adf-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitRootTestADFCardHelpComponent {
    @Input() params: UnitRootTestADFCard;

    get stationarityLabel(): string {
        switch (this.params.regressionMode) {
            case UnitRootTestADF.RegressionMode.NO_CONSTANT_NO_TREND:
            case UnitRootTestADF.RegressionMode.CONSTANT_ONLY:
                return "stationary";

            case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_TREND:
            case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_QUADRATIC_TREND:
                return "trend stationary";
        }
    }
}
