import { Injectable } from '@angular/core';
import { StaticCanvas } from 'fabric/fabric-impl';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeedImageModalService {
    private canvasSource$ = new Subject<StaticCanvas>();
    private imageIdSource$ = new ReplaySubject<number>(1);
    canvas$ = this.canvasSource$.asObservable();
    imageId$ = this.imageIdSource$.asObservable();

    constructor() { }

    setImageId(id: number) {
        this.imageIdSource$.next(id);
    }

    setCanvas(canvas: StaticCanvas) {
        this.canvasSource$.next(canvas);
    }
}
