<div class="deephub-design-image-feed">
    <!-- Temporary solution to validate backend filtering works, TODO: better way of filtering/displaying selected categories -->
    <form class="deephub-design-image-feed__filter-form dkuform-horizontal" [formGroup]="form">
        <div class="control-group">
            <label class="control-label">Filter displayed classes</label>
            <div class="controls" >
                <dku-bs-select-form-control
                    formControlName="selectedCategories"
                    ngOptions="category for category in list"
                    [list]="categoriesList"
                    dataActionsBox="true"
                    dataLiveSearch="true"
                    dkuMultiple="multiple"
                    [layout]="'list'">
                </dku-bs-select-form-control>
            </div>
        </div>
    </form>

    <image-feed class="deephub-design-image-feed__feed" [imageHeight]="200" [itemsPerRow]="3" (imageClick)="openModal($event)"></image-feed>
</div>
