import {ComputationResult} from './../computation-result';
import {Computation} from './../computation';
import {UnitRootTest, _UnitRootTest} from './unit-root-test';

/**
 * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS
 */
export interface UnitRootTestKPSS extends _UnitRootTest {
    regressionMode: UnitRootTestKPSS.RegressionMode;

    // PolyJson type
    type: 'unit_root_kpss';
}

export function isUnitRootTestKPSS(toTest: Computation): toTest is UnitRootTestKPSS { return 'unit_root_kpss' === toTest.type; }

export namespace UnitRootTestKPSS {
    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$RegressionMode
     */
    export enum RegressionMode {
        CONSTANT = 'CONSTANT',
        CONSTANT_WITH_TREND = 'CONSTANT_WITH_TREND'
    }

    /**
     * Generated from com.dataiku.dip.eda.compute.computations.timeseries.UnitRootTestKPSS$UnitRootTestKPSSResult
     */
    export interface UnitRootTestKPSSResult extends UnitRootTest._UnitRootTestResult {
        // PolyJson type
        type: 'unit_root_kpss';
    }

    export function isUnitRootTestKPSSResult(toTest: ComputationResult): toTest is UnitRootTestKPSSResult { return 'unit_root_kpss' === toTest.type; }
}