import { Sample, WorksheetRootCard, CardResult } from 'src/generated-sources';
import { of, combineLatest, EMPTY, race, merge, from } from 'rxjs';
import { map, switchMap, catchError, pairwise, filter, distinctUntilChanged, skip, takeUntil, withLatestFrom, debounceTime, tap } from 'rxjs/operators';
import deepEqual from 'fast-deep-equal';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { deepDistinctUntilChanged, auditMap, combineLatestObject } from 'dku-frontend-core';
import { getWorksheetObjectRef } from '../utils';
import { WaitingService } from '@core/overlays/waiting.service';
import { CollapsingService, CollapsibleTopLevelCard } from '../collapsing.service';
import { worksheetSavedTransition, setErrorTransition, sampleLoadedTransition, worksheetInitialLoadTransition, requestCardTransition, resultsReceivedTransition, datasetLoaded } from './transitions';
import { Process, StateSelectors } from './state';
import { ComputeService } from '../compute.service';
import { WT1Service } from '@core/dataiku-wt1/wt1.service';
import { getWorksheetStats } from '../card-utils';

// Background processes
export class Processes {
    constructor(
        private DataikuAPI: DataikuAPIService,
        private waitingService: WaitingService,
        private collapsingService: CollapsingService,
        private selectors: StateSelectors,
        private computeService: ComputeService,
        private wt1Service: WT1Service
    ) { }

    getAllProcesses() {
        return merge(
            this.worksheetComputer(),
            this.worksheetLoader(),
            this.sampleLoader(),
            this.worksheetSaver(),
            this.collapsingWatcher(),
            this.datasetLoader()
        );
    }

    worksheetSaver = (): Process => {
        return combineLatest([
            this.selectors.state$.pipe(
                map(state => state.dirtyWorksheet),
                distinctUntilChanged()
            ),
            this.selectors.getWorksheet().pipe(distinctUntilChanged())
        ]).pipe(
            filter(([dirtyWorksheet]) => !!dirtyWorksheet),
            switchMap(([dirtyWorksheet, currentWorksheet]) => {
                if (!dirtyWorksheet) {
                    return EMPTY;
                }

                return this.DataikuAPI.statistics.save(dirtyWorksheet!).pipe(
                    tap(savedWorksheet => {
                        this.wt1Service.event('save-worksheet', getWorksheetStats(savedWorksheet));
                    }),
                    this.waitingService.bindSpinner(),
                    map(savedWorksheet => worksheetSavedTransition(savedWorksheet)),
                    catchError((err) => of(setErrorTransition(err)))
                );
            })
        );
    }

    worksheetComputer = (): Process => {
        const computeTrigger$ = combineLatest([
            this.selectors.getCardsToCompute(),
            this.selectors.getLoc(),
            this.selectors.getSample()
        ]).pipe(deepDistinctUntilChanged(), debounceTime(100));

        const dataSpecChanged$ = this.selectors.getDataSpec().pipe(skip(1));
        const worksheetLocChanged$ = this.selectors.getWorksheetLoc().pipe(skip(1));
        const abortCondition$ = race(dataSpecChanged$, worksheetLocChanged$);

        return computeTrigger$.pipe(
            auditMap(([cards, loc, sample]) => {
                if (!loc || !sample || cards.length === 0) {
                    return EMPTY;
                }
                const fakeRootCard: WorksheetRootCard = {
                    type: 'worksheet_root',
                    cards,
                    // This is a fake container card (backend can't compute Card[])
                    confidenceLevel: 1,
                    id: 'abc',
                    showConfidenceInterval: true
                };

                return this.computeService.computeCard(fakeRootCard, sample.id, true).pipe(
                    map(resp => {
                        const rootCardResult = resp as WorksheetRootCard.WorksheetRootCardResult;
                        return resultsReceivedTransition(rootCardResult.results.map((result, index) => ({
                            cardParams: fakeRootCard.cards[index],
                            cardResult: result,
                            sampleId: sample.id
                        })));
                    }),
                    catchError(err => {
                        // Display error within cards
                        const failedResults = cards.map(card => ({
                            cardParams: card,
                            cardResult: {
                                type: 'unavailable',
                                reason: CardResult.UnavailabilityReason.FAILURE,
                                message: err && err.message ? err.message : 'Unexpected error'
                            } as CardResult,
                            sampleId: sample.id
                        }));

                        const transitions = [resultsReceivedTransition(failedResults)];

                        // Display full error globally (except if failure is caused by user abort)
                        if (err.errorType !== 'FutureAbort') {
                            transitions.push(setErrorTransition(err));
                        }
                        return from(transitions);
                    }),
                    takeUntil(abortCondition$)
                );
            })
        );
    }

    datasetLoader = (): Process => {
        return combineLatest([
            this.selectors.getDatasetLoc(),
            this.selectors.getDataset().pipe(filter(dataset => !dataset)),
        ]).pipe(
            withLatestFrom(this.selectors.getWorksheetLoc()),
            auditMap(([[datasetLoc, _], worksheetLoc]) => {
                if (!datasetLoc || !worksheetLoc) {
                    return EMPTY;
                }

                return this.DataikuAPI.datasets.get(datasetLoc.projectKey, datasetLoc.id, worksheetLoc.projectKey)
                    .pipe(
                        this.waitingService.bindSpinner(),
                        map(dataset => datasetLoaded(dataset)),
                        catchError((err) => of(setErrorTransition(err))),
                    );
            })
        );
    }

    worksheetLoader = (): Process => {
        return combineLatest([
            this.selectors.getWorksheetLoc(),
            this.selectors.getWorksheet()
        ]).pipe(
            deepDistinctUntilChanged(),
            pairwise(),
            filter(([[prevLoc, prevWorksheet], [newLoc, newWorksheet]]) => {
                if (!prevLoc && newLoc && !newWorksheet) {
                    // Worksheet not loaded yet
                    return true;
                }
                if (prevLoc && newLoc && !deepEqual(prevLoc, newLoc)) {
                    // Worksheet loc has changed
                    return true;
                }
                // TODO: should we handle worksheet unloading (return to initial state?)
                return false;
            }),
            map(([[prevLoc, prevWorksheet], [newLoc, newWorksheet]]) => newLoc!),
            switchMap(({ projectKey, id }) =>
                this.DataikuAPI.statistics.get(projectKey, id).pipe(
                    this.waitingService.bindSpinner(),
                    map(worksheet => worksheetInitialLoadTransition(worksheet)),
                    catchError((err) => of(setErrorTransition(err)))
                )
            )
        );
    }

    collapsingWatcher = (): Process => {
        return this.selectors.getRootCard().pipe(
            switchMap(rootCard => {
                if (rootCard) {
                    return combineLatest(
                        rootCard.cards.map(card => {
                            return this.collapsingService
                                .watchIsCollapsed(new CollapsibleTopLevelCard(card.id))
                                .pipe(map(collapsed => ({ id: card.id, collapsed })));
                        })
                    ).pipe(
                        deepDistinctUntilChanged(),
                        map(states => requestCardTransition(
                            states
                                .filter(({ collapsed }) => !collapsed)
                                .map(({ id }) => id))
                        )
                    );
                }
                return EMPTY;
            })
        );
    }

    sampleLoader = (): Process => {
        return combineLatestObject({
            cardsToCompute: this.selectors.getCardsToCompute(),
            sample: this.selectors.getSample(),
            worksheet: this.selectors.getWorksheet(),
            sampleExternallyRequested: this.selectors.isSampleExternallyRequested()
        }).pipe(
            // Only create a sample if needed (when there is a card to compute and no sample)
            filter(({ cardsToCompute, sample, worksheet, sampleExternallyRequested }) =>
                !sample && !!worksheet && (cardsToCompute.length > 0 || sampleExternallyRequested)
            ),

            // Ignore unrelated state changes
            map(({ worksheet }) => ({
                dataSpec: worksheet!.dataSpec,
                sampleKey: getWorksheetObjectRef(worksheet!)
            })),
            deepDistinctUntilChanged(),

            // Sample need to be retrieve or rebuild
            switchMap(({ dataSpec, sampleKey }) => {
                return this.DataikuAPI.statistics.currentSample(sampleKey, dataSpec)
                    .pipe(
                        switchMap(currentSampleResponse => {
                            // Sample was already built
                            if (currentSampleResponse) {
                                return of(sampleLoadedTransition(currentSampleResponse));
                            }

                            // Otherwise build a new sample
                            return this.DataikuAPI.statistics.rebuildSample(sampleKey, dataSpec)
                                .pipe(
                                    this.waitingService.bindOverlayAndWaitForResult<Sample>(),
                                    map(newSample => sampleLoadedTransition(newSample))
                                );
                        }),
                        this.waitingService.bindStaticOverlay('Preparing sample...')
                    ).pipe(catchError((err) => of(setErrorTransition(err))));
            })
        );
    }
}
