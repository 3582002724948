<div class="card-layout">
    <div class="card-layout__section card-layout__section--horizontal">

        <div class="card-layout__section">
            <div class="card-layout__title">Summary</div>
            <div class="card-layout__content">
                <table class="stats-table">
                    <tbody class="stats-table__body">
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Count</th>
                            <td class="stats-table__cell">{{ results.boxPlot.countComplete }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Series mean</th>
                            <td class="stats-table__cell">{{ results.boxPlot.mean | smarterNumber }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Series standard deviation</th>
                            <td class="stats-table__cell">{{ results.boxPlot.stdDev | smarterNumber }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Series box plot</th>
                            <td class="stats-table__cell boxplot-cell">
                                <embedded-box-plot
                                    [variableName]="params.seriesColumn.name"
                                    [boxPlot]="results.boxPlot"
                                ></embedded-box-plot>
                            </td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Series ordering</th>
                            <td class="stats-table__cell">{{ params.timeColumn.name }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Min timestamp</th>
                            <td class="stats-table__cell timestamp-cell">{{ results.timeInfo.min }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Max timestamp</th>
                            <td class="stats-table__cell timestamp-cell">{{ results.timeInfo.max }}</td>
                        </tr>
                        <tr class="stats-table__row">
                            <th class="stats-table__head">Regression mode</th>
                            <td class="stats-table__cell">{{ regressionLabel }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="card-layout__section card-layout__section--vertical">
            <div class="card-layout__section">
                <div class="card-layout__title">Hypothesis</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Tested hypothesis</th>
                                <td class="stats-table__cell">{{ nullHypothesisLabel }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Significance level</th>
                                <td class="stats-table__cell">{{ 1 - params.confidenceLevel | smarterNumber }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-layout__section">
                <div class="card-layout__title">Results</div>
                <div class="card-layout__content">
                    <table class="stats-table">
                        <tbody class="stats-table__body">
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Used lag</th>
                                <td class="stats-table__cell">{{ results.usedLag }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Number of observations</th>
                                <td class="stats-table__cell">{{ results.nbObservations }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">Statistic</th>
                                <td class="stats-table__cell">{{ results.statistic | smarterNumber }}</td>
                            </tr>
                            <tr class="stats-table__row">
                                <th class="stats-table__head">
                                    <ng-container *ngIf="isADF else pvalue">
                                        MacKinnon’s approximate 𝘱-value
                                    </ng-container>
                                    <ng-template #pvalue>
                                        𝘱-value
                                    </ng-template>
                                </th>
                                <td class="stats-table__cell">
                                    <colored-pvalue
                                        [pvalue]="results.pValue"
                                        [significanceLevel]="1 - params.confidenceLevel"
                                    ></colored-pvalue>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="test-conclusion">
    <ng-container *ngIf="isRejected else inconclusive">
        <p class="test-conclusion__wording test-conclusion__wording--rejected">
            <span *ngIf="isADF">
                The series <u>{{ params.seriesColumn.name }}</u> has no unit root. It is {{ stationarityLabel }}.
            </span>
            <span *ngIf="isZA">
                The series <u>{{ params.seriesColumn.name }}</u> has no unit root with a single structural break. It is {{ stationarityLabel }}.
            </span>
            <span *ngIf="isKPSS">
                The series <u>{{ params.seriesColumn.name }}</u> is not {{ stationarityLabel }}. It has a unit root.
            </span>
        </p>
    </ng-container>

    <ng-template #inconclusive>
        <p class="test-conclusion__wording">
            <span *ngIf="isADF">
                The test is inconclusive about whether <u>{{ params.seriesColumn.name }}</u> has a unit root.
            </span>
            <span *ngIf="isZA">
                The test is inconclusive about whether <u>{{ params.seriesColumn.name }}</u> has a unit root with a single structural break.
            </span>
            <span *ngIf="isKPSS">
                The test is inconclusive about wheter <u>{{ params.seriesColumn.name }}</u> is {{ stationarityLabel }}.
            </span>
        </p>
    </ng-template>
</div>
