import { Inject, Injectable } from "@angular/core";
import { WT1Service } from "@core/dataiku-wt1/wt1.service";
import { ConfirmModalComponent } from "@shared/modals/confirm-modal/confirm-modal.component";
import { ModalShape, ModalsService } from "@shared/modals/modals.service";
import { Workspace } from "src/generated-sources";
import { EditTabs, EditWorkspaceModalComponent } from "../modals/edit-workspace-modal/edit-workspace-modal.component";
import { WorkspaceDisplayService } from "../workspaces-migration";
import { ATSurveyService } from "../../surveys/surveys-migration";
import { WorkspacesService } from "../workspaces.service";
import { WorkspaceWT1Service } from "./workspace-wt1.service";

@Injectable()
export class WorkspaceModalService {

    constructor(
        private workspacesService: WorkspacesService,
        private modalsService: ModalsService,
        private WT1: WT1Service,
        private workspaceWT1: WorkspaceWT1Service,
        @Inject('WorkspaceDisplayService') private WorkspaceDisplayService: WorkspaceDisplayService,
        @Inject('ATSurveyService') private ATSurveyService: ATSurveyService,
    ) {
    }

    createWorkspace(): void {
        this.WT1.event('workspaces-create-workspace-open', {});
        const data = {
            error: this.workspacesService.getError(),
            submit: (newWorkspace: Workspace) => newWorkspace ? this.workspacesService.createWorkspace(newWorkspace) : null,
        };

        this.modalsService.open(EditWorkspaceModalComponent, data, ModalShape.NONE)
            .then((newWorkspace: Workspace) => this.WT1.event('workspaces-create-workspace-success', this.workspaceWT1.buildWT1Event(newWorkspace)))
            .catch(() => this.WT1.event('workspaces-create-workspace-cancel', {}));
    }

    editWorkspace(workspace: Workspace, from: string, tabs?: EditTabs[]): void {
        this.WT1.event('workspaces-edit-workspace-open', { from });
        const data = {
            workspace,
            tabs,
            error: this.workspacesService.getError(),
            submit: (updatedWorkspace: Workspace) => updatedWorkspace ? this.workspacesService.updateWorkspace(updatedWorkspace) : null,
        };

        // An AngularJS modal can be opened from EditWorkspaceModalComponent, so we need to handle the closing by hand.
        this.modalsService.open(EditWorkspaceModalComponent, data, ModalShape.NONE, undefined, { disableClose: true })
            .then((updatedWorkspace: Workspace) => {
                this.WT1.event('workspaces-edit-workspace-success', this.workspaceWT1.buildWT1ChangeEvent(workspace, updatedWorkspace, from));
                // Call AT Survey
                this.ATSurveyService.updateCounter('WorkspacesOpenEditObject');
            }).catch(() => this.WT1.event('workspaces-edit-workspace-cancel', { from }));
    }

    deleteWorkspace(workspace: Workspace): void {
        this.WT1.event('workspaces-delete-workspace-open', {});
        this.modalsService.open(ConfirmModalComponent, {
            title: `Delete workspace '${workspace.displayName}'`,
            message: `Do you really want to delete workspace '${workspace.displayName}'?`,
            danger: true,
            confirm: 'Yes, delete this workspace',
        }).then(() => {
            this.workspacesService.deleteWorkspace(workspace.workspaceKey).subscribe(() => {
                this.WT1.event('workspaces-delete-workspace-success', this.workspaceWT1.buildWT1Event(workspace));
            });
        }).catch(() => {
            this.WT1.event('workspaces-delete-workspace-cancel', {})
        });
    }

    removeWorkspaceObject(workspace: Workspace, object: Workspace.WorkspaceObject): void {
        const objectType = this.WorkspaceDisplayService.getObjectType(object);

        this.WT1.event('workspaces-remove-object-open', { objectType });
        this.modalsService.open(ConfirmModalComponent, {
            title: `Remove "${object.displayName}" from workspace`,
            message: `"${object.displayName}" will be removed from this workspace` + (object.htmlLink ? '.' : ', it will not be deleted.'),
            danger: true,
            confirm: 'Remove',
        }).then(() => {
            this.workspacesService.removeWorkspaceObject(workspace, object).subscribe(() => {
                this.WT1.event('workspaces-remove-object-success', { objectType });
            });
        }).catch(() => {
            this.WT1.event('workspaces-remove-object-cancel', { objectType });
        });
    }

}
