import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeephubObjectDetectionDesignCanvasPainterService } from '@features/deephub/object-detection/design/services/deephub-object-detection-design-canvas-painter.service';
import { Column } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { PainterService } from '@shared/services/painter.service';

@Component({
    selector: 'deephub-object-detection-design-image-feed-modal',
    templateUrl: './deephub-object-detection-design-image-feed-modal.component.html',
    styleUrls: ['./deephub-object-detection-design-image-feed-modal.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DeephubObjectDetectionDesignCanvasPainterService,
        { provide: PainterService, useExisting: DeephubObjectDetectionDesignCanvasPainterService },
    ]
})
export class DeephubObjectDetectionDesignImageFeedModalComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { 
            imageId: number, 
            columns: Column[]
        },
        public colorMapService: ColorMapContextService
    ) {}
}
