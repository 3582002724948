<table class="stats-table">
    <tbody>
        <ng-container *ngFor="let stat of results.stats; let index = index; trackBy: trackByIndex">
            <tr class="stats-table__row">
                <th class="stats-table__head">
                    <span [matTooltip]="stat.fullLabel">{{ stat.shortLabel }}</span>
                    <button
                        *ngIf="canCreateCardFromTemplate(stat)"
                        class="btn btn--icon btn--secondary add-card-button"
                        (click)="createCardFromTemplate(stat)"
                        matTooltip="Add card"
                        matTooltipPosition="right"
                    >
                        <i class="icon icon-plus-sign"></i>
                    </button>
                </th>
                <td class="stats-table__cell">
                    <div *ngIf="stat.warning" class="pull-right stat-warning">
                        <i class="icon-warning-sign" [matTooltip]="stat.warning"></i>
                    </div>

                    <span *ngIf="stat.type == 'INT' && stat.intValue != null" [matTooltip]="stat.intValue">
                        {{ stat.intValue }}
                    </span>

                    <span *ngIf="stat.type == 'FAILURE'" [matTooltip]="stat.failureMessage">
                        N/A
                    </span>

                    <span *ngIf="stat.type == 'NOT_COMPUTED'">
                        <span class="dku-loader icon-spin card-spinner card-spinner__stats-table"></span>
                    </span>

                    <span *ngIf="stat.type == 'FLOAT' && stat.floatValue != null" [matTooltip]="stat.floatValue">
                        {{ stat.floatValue | smarterNumber }}
                    </span>

                    <span *ngIf="stat.type == 'FILTERS' && stat.filterValues != null">
                        <ng-container *ngFor="let filter of stat.filterValues; let first = first">
                            {{ first ? '' : ',' }}
                            {{ filter | filterName | slice:0:100 }}<ng-container *ngIf="(filter | filterName).length > 100">...</ng-container>
                        </ng-container>
                    </span>
                </td>
            </tr>

            <ng-container *ngIf="stat.pvalue != null">
                <tr class="stats-table__row--invisible">
                    <!-- Fake row to temporarily skip alternate color -->
                </tr>
                <tr class="stats-table__row">
                    <th class="stats-table__head stats-table__head--derived">𝘱-value</th>
                    <td class="stats-table__cell stats-table__cell--derived">
                        <colored-pvalue [pvalue]="stat.pvalue" [significanceLevel]="1 - params.confidenceLevel">
                        </colored-pvalue>
                    </td>
                </tr>
            </ng-container>

            <ng-container *ngIf="stat.lower != null && stat.upper != null">
                <tr class="stats-table__row--invisible">
                    <!-- Fake row to temporarily skip alternate color -->
                </tr>
                <tr class="stats-table__row">
                    <th class="stats-table__head stats-table__head--derived" [matTooltip]="'Confidence interval'">Conf. int.</th>
                    <td class="stats-table__cell stats-table__cell--derived">
                        [<span [matTooltip]="stat.lower">{{ stat.lower | smarterNumber }}</span>,
                        <span [matTooltip]="stat.upper">{{ stat.upper | smarterNumber }}</span>]
                    </td>
                </tr>
            </ng-container>
        </ng-container>
    </tbody>
</table>