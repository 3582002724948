import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DurbinWatsonCard } from "src/generated-sources";

@Component({
    selector: 'durbin-watson-card-help',
    templateUrl: './durbin-watson-card-help.component.html',
    styleUrls: ['./durbin-watson-card-help.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DurbinWatsonCardHelpComponent {
    @Input() params: DurbinWatsonCard;
}
