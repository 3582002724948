import {AbstractNSampTestCard} from './abstract-nsamp-test-card';
import {CardResult, _CardResult} from './../card-result';
import {Card} from './../card';
import {KsTest2SampCard} from './../ks-test2-samp-card';
import {MoodTest2SampCard} from './../mood-test2-samp-card';
import {TTest2SampCard} from './../ttest2-samp-card';
import {_BivariateCard} from './../bivariate-card';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard
 */
export interface _Abstract2SampTestCard extends _BivariateCard {
    confidenceLevel: number;
    value1?: string[] | null;
    value2?: string[] | null;
}

export type Abstract2SampTestCard = MoodTest2SampCard | TTest2SampCard | KsTest2SampCard;

export function isAbstract2SampTestCard(toTest: Card): toTest is Abstract2SampTestCard { return ["mood_test_2samp" , "ttest_2samp" , "ks_test_2samp"].includes(toTest.type); }

export namespace Abstract2SampTestCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.common.Abstract2SampTestCard$Abstract2SampTestCardResult
     */
    export interface _Abstract2SampTestCardResult extends _CardResult {
        allGroups: AbstractNSampTestCard.GroupInfo;
        groups: AbstractNSampTestCard.GroupInfo[];
    }

    export type Abstract2SampTestCardResult = MoodTest2SampCard.MoodTest2SampCardResult | TTest2SampCard.TTest2SampCardResult | KsTest2SampCard.KsTest2SampCardResult;

    export function isAbstract2SampTestCardResult(toTest: CardResult): toTest is Abstract2SampTestCardResult { return ["mood_test_2samp" , "ttest_2samp" , "ks_test_2samp"].includes(toTest.type); }
}