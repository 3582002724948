import { Inject, Injectable } from '@angular/core';
import { DataikuAPIService } from '@core/dataiku-api/dataiku-api.service';
import { CurrentRouteService } from '@core/nav/current-route.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { DeepHubColumnFormat, DeephubImagesDataService, ResolvedDeepHubPredictionCoreParams, SerializedMemTableV2, SerializedTableChunk } from 'src/generated-sources';
import { AbstractDeephubDataFetcherService, DeephubCellData, DeephubColumn, DeephubDataColumns } from '@features/deephub/services/abstract-deephub-data-fetcher.service';
import { DeephubObjectDetectionReportCanvasPainterService } from './deephub-object-detection-report-canvas-painter.service';
import { DeephubObjectDetectionReportService, ObjectDetectionReport } from './deephub-object-detection-report.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';
import { AbstractDeephubObjectDetectionReportPainterService } from './abstract-deephub-object-detection-report-painter.service';

export interface DeephubObjectDetectionReportCellData extends DeephubCellData {
    prediction: DeepHubColumnFormat.ObjectDetectionPredictedItem[],
    pairing: DeepHubColumnFormat.PairingItem[],
    enrichedValid: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[],
    enrichedFiltered: DeepHubColumnFormat.EnrichedObjectDetectionPairedItem[],
}

export interface DeephubResultsDataColumns extends DeephubDataColumns {
    prediction: DeephubColumn,
    pairing: DeephubColumn,
    enrichedValid: DeephubColumn,
    enrichedFiltered: DeephubColumn,
}

@UntilDestroy()
@Injectable()
export class DeephubObjectDetectionReportDataFetcherService extends AbstractDeephubDataFetcherService {
    coreParams: ResolvedDeepHubPredictionCoreParams;
    dataColumns: DeephubResultsDataColumns;

    report$: Observable<ObjectDetectionReport>;
    filter$ = new BehaviorSubject<{
        detection?: DeephubImagesDataService.Category | null;
        groundTruth?: DeephubImagesDataService.Category | null;
        sorting: DeephubImagesDataService.Sorting;
    } | null>({sorting: {sortBy: DeephubImagesDataService.SortBy.IOU, ascending: false}});
    fetchTrigger$: Observable<[ObjectDetectionReport, DeephubImagesDataService.ObjectDetectionPredictedFilter | null]>;

    constructor(
        private DataikuAPI: DataikuAPIService,
        private objectDetectionService: DeephubObjectDetectionReportService,
        private canvasPainter: AbstractDeephubObjectDetectionReportPainterService<void>,
        private currentRoute: CurrentRouteService,
        private colorMapService: ColorMapContextService,
        @Inject('COLOR_PALETTES') private COLOR_PALETTES: { [palette: string]: string[]; }
    ) {
        super();
        this.projectKey = this.currentRoute.projectKey;
        this.report$ = this.objectDetectionService.getReport().pipe(
            debounceTime(400)
        );

        this.report$.
            pipe(
                withLatestFrom(this.filter$),
                // only refresh data if detection/ground truth filtering is active
                distinctUntilChanged(([, prevFilter], [, currFilter]) => {
                    return !currFilter || !currFilter.detection || !currFilter.groundTruth;
                }),
                untilDestroyed(this)
            ).subscribe(([report, filter]) => {
                this.coreParams = report.coreParams;
                this.managedFolderId = report.coreParams.managedFolderSmartId;
                this.colorMapService.setMapping(report.categories, this.COLOR_PALETTES.highContrast);

                this.onDataChanged();
            });

        this.filter$.pipe(
            untilDestroyed(this)
        ).subscribe(filter => {
            this.canvasPainter.setClasses(filter?.groundTruth?.value, filter?.detection?.value);
        })

        this.fetchTrigger$ = combineLatest([this.report$, this.filter$])
            .pipe(
                map(([report, filter]) => [report, filter ? {
                    minConfidence: report.confidenceThreshold!,
                    minIOU: report.iou,
                    ...filter
                } : filter]),
            );
    }

    refreshSample(): Observable<SerializedMemTableV2> {
        return this.fetchTrigger$
            .pipe(
                switchMap(([report, filter]) => this.DataikuAPI.analysis.refreshPredictedImagesDataSample(report.fullModelId, this.CHUNK_SIZE, filter || {})
                    .pipe(
                        map((result) => {
                            this.onRefreshSample(result);

                            return result;
                        })
                    )
                )
            );
    }

    getSampleChunk(firstRow: number, nbRows: number): Observable<SerializedTableChunk> {
        return this.fetchTrigger$
            .pipe(
                switchMap(([report, filter]) => this.DataikuAPI.analysis.getPredictedImagesDataChunk(report.fullModelId, firstRow, nbRows, filter || {}))
            );
    }

    createDataColumns(orderedColumns: string[]): DeephubResultsDataColumns {
        const columns: DeephubResultsDataColumns = {
            target: {
                name: this.coreParams.target_variable
            },
            itemPath: {
                name: this.coreParams.pathColumn
            },
            prediction: {
                name: 'prediction',
            },
            pairing: {
                name: 'pairing',
            },
            enrichedValid: {
                name: 'enrichedValid',
            },
            enrichedFiltered: {
                name: 'enrichedFiltered'
            }
        };

        this.addColumnIndices(columns, orderedColumns);

        return columns;
    }

    rowToCellData(rowContent: string[], i: number, j: number): DeephubObjectDetectionReportCellData {
        const columns = this.dataColumns as DeephubResultsDataColumns;
        return {
            itemPath: rowContent[columns.itemPath.index!],
            target: JSON.parse(rowContent[columns.target.index!]),
            prediction: JSON.parse(rowContent[columns.prediction.index!]),
            pairing: JSON.parse(rowContent[columns.pairing.index!]),
            enrichedValid: JSON.parse(rowContent[columns.enrichedValid.index!]),
            enrichedFiltered: JSON.parse(rowContent[columns.enrichedFiltered.index!]),
            imageId: i
        };
    }

    setFilteredPair(filter: {
        detection?: DeephubImagesDataService.Category | null;
        groundTruth?: DeephubImagesDataService.Category | null;
        sorting: DeephubImagesDataService.Sorting
    } | null) {
        this.filter$.next(filter);
        this.onDataChanged();
    }
}
