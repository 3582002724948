
<div class="image-feed-image" [ngClass]="{ 'image-feed-image--selected': imageSelected  }" [style.height]="imageHeight ? imageHeight - margin + 'px' : ''">
    <div class="image-feed-image__container">
        <img class="image-feed-image__img" #imageEl [src]="imgUrl" [class.display-none]="hasError" (load)="onImageLoad()" (error)="onImageError($event)" />
        <ng-container *ngIf="showArtifacts">
            <div class="image-feed-image__artifacts" [style]="artifact.style" *ngFor="let artifact of painterImage.artifacts"></div>
        </ng-container>
    </div>
    <image-placeholder *ngIf="hasError || !hasLoaded" [hasLoaded]="hasLoaded" [size]="imageHeight"></image-placeholder>
</div>
