import { Injectable } from '@angular/core';
import { Chunk } from '@shared/components/infinite-scroll/infinite-scroll.component';
import { ManagedFolder } from '@utils/managed-folder';
import { Observable, ReplaySubject } from 'rxjs';

export interface CellData {
    itemPath: string,
    imageId: number
}

@Injectable()
export abstract class ImagesDataFetcherService {
    readonly CHUNK_SIZE = 64;

    projectKey: string;
    managedFolderId: string;

    private subject = new ReplaySubject<void>(1);
    dataChanged$: Observable<void> = this.subject.asObservable();

    abstract getChunk(offset: number): Observable<Chunk>;

    abstract rowToCellData(rowContent: string[], i: number, j: number): CellData;

    getImagePath(itemPath: string): string {
        return new ManagedFolder(this.projectKey, this.managedFolderId).getImagePath(itemPath);
   }

    onDataChanged(): void {
        this.subject.next();
    }
}
