import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { DeephubImagesDataService, DeepHubPredictionModelPerf, ResolvedDeepHubPredictionCoreParams } from 'src/generated-sources';

export interface ObjectDetectionReport {
    coreParams: ResolvedDeepHubPredictionCoreParams,
    fullModelId: string,
    perf: DeepHubPredictionModelPerf,
    categories: string[],
    confidenceThreshold?: number,
    iou: number
}

@Injectable({
  providedIn: 'root'
})
export class DeephubObjectDetectionReportService {
    private report$ = new ReplaySubject<ObjectDetectionReport>(1);

    constructor() { }

    updateReport(report: ObjectDetectionReport) {
        this.report$.next(report);
    }

    getReport(): Observable<ObjectDetectionReport> {
        return this.report$;
    }

    // Converts a value on a scale to its position (index)
    private stepValueToIndex(value: number, startValue: number, step: number) {
        return Math.round((value - startValue) / step);
    }

    iouToIndex(iou: number): number {
        return this.stepValueToIndex(iou, 0.5, 0.05);
    }

    confidenceThresholdToIndex(confidenceThreshold: number): number {
        return this.stepValueToIndex(confidenceThreshold, 0, 0.05);
    }

    /*
        Category clicked: filter type = ONE for row/column clicked, ANY for other axis
        Cell clicked: filter type = ONE if in category row/column, NONE if no detection/object row/column
    */
    getFilterCategory(index: number, categories: string[]): DeephubImagesDataService.Category {
        const categoryCount = categories.length;
        let value = '';
        let type = DeephubImagesDataService.CategoryType.ONE;
        
        if (index < 0) { // no category was clicked on, so don't filter any out
            type = DeephubImagesDataService.CategoryType.ANY;
        } else if (index >= categoryCount) { // no detection/object cell clicked
            type = DeephubImagesDataService.CategoryType.NONE;
        } else { // specific category or cell with category was clicked on
            value = categories[index];
        }

        return {
            type,
            value
        }
    }
}
