import {CardResult} from './card-result';
import {Card} from './card';
import {UnitRootTestCard, _UnitRootTestCard} from './unit-root-test-card';
import {UnitRootTestKPSS} from './../../compute/computations/timeseries/unit-root-test-kpss';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard
 */
export interface UnitRootTestKPSSCard extends _UnitRootTestCard {
    regressionMode: UnitRootTestKPSS.RegressionMode;

    // PolyJson type
    type: 'unit_root_test_kpss';
}

export function isUnitRootTestKPSSCard(toTest: Card): toTest is UnitRootTestKPSSCard { return 'unit_root_test_kpss' === toTest.type; }

export namespace UnitRootTestKPSSCard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestKPSSCard$UnitRootTestKPSSCardResult
     */
    export interface UnitRootTestKPSSCardResult extends UnitRootTestCard._UnitRootTestCardResult {
        // PolyJson type
        type: 'unit_root_test_kpss';
    }

    export function isUnitRootTestKPSSCardResult(toTest: CardResult): toTest is UnitRootTestKPSSCardResult { return 'unit_root_test_kpss' === toTest.type; }
}