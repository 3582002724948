export class ManagedFolder {

    projectKey: string;
    managedFolderId: string;

    constructor(projectKey: string, managedFolderId: string, ) {
        this.projectKey = projectKey;
        this.managedFolderId = managedFolderId;
    }

    getImagePath(itemPath: string): string {
        let contentType = 'image/jpeg';
        // keep in sync with PathStats::SUPPORTED_IMAGES
        if (itemPath.endsWith('.png')) {
            contentType = 'image/png';
        }
        return `/dip/api/managedfolder/preview-image?projectKey=${encodeURIComponent(this.projectKey)}&odbId=${encodeURIComponent(this.managedFolderId)}&itemPath=${encodeURIComponent(itemPath)}&contentType=${encodeURIComponent(contentType)}`;
    }
}
