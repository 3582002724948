import { Component, ChangeDetectionStrategy, ViewContainerRef } from '@angular/core';
import { FeedImageModalParams } from '@shared/components/feed-image-modal/feed-image-modal.component';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModalShape, ModalsService } from '@shared/modals/modals.service';
import { DeephubImagesDataService } from 'src/generated-sources';
import { DeephubObjectDetectionReportImageFeedModalComponent } from '@features/deephub/object-detection/report/image-feed-modal/deephub-object-detection-report-image-feed-modal.component';
import { DeephubObjectDetectionReportDataFetcherService } from '../services/deephub-object-detection-report-data-fetcher.service';

@UntilDestroy()
@Component({
  selector: 'deephub-object-detection-report-image-feed',
  templateUrl: './deephub-object-detection-report-image-feed.component.html',
  styleUrls: ['./deephub-object-detection-report-image-feed.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeephubObjectDetectionReportImageFeedComponent {
    constructor(
        public dataFetcher: DeephubObjectDetectionReportDataFetcherService,
        public viewContainerRef: ViewContainerRef,
        public modalsService: ModalsService
    ) {}

    openModal({ imageId }: FeedImageModalParams) {
        const IGNORED_COLUMNS = [
            this.dataFetcher.dataColumns.target.name,
            this.dataFetcher.dataColumns.prediction.name,
            this.dataFetcher.dataColumns.pairing.name,
            this.dataFetcher.dataColumns.enrichedFiltered.name,
            this.dataFetcher.dataColumns.enrichedValid.name
        ];
        const IGNORED_PREFIX = "__dku__deephub_";
        const columns = this.dataFetcher.tableData!.allColumnNames.map((name, index) => ({
            name,
            index
        })).filter(column => !IGNORED_COLUMNS.includes(column.name) && !column.name.startsWith(IGNORED_PREFIX));
        this.modalsService
            .open(DeephubObjectDetectionReportImageFeedModalComponent, {
                imageId,
                columns,
                imageOptions: {
                    showResultIcon: true,
                    selectedStrokeWidth: 3
                }
             }, ModalShape.FULL, this.viewContainerRef)
            .then(() => {}, () => {});
    }

    public get SortBy() {
        return DeephubImagesDataService.SortBy;
    }

    private setSorting(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter, sorting: DeephubImagesDataService.Sorting) {
        this.dataFetcher.setFilteredPair({
            detection: filter.detection,
            groundTruth: filter.groundTruth,
            sorting
        });
    }

    setSortingByConfidence(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter) {
        this.setSorting(filter, {
            sortBy: DeephubImagesDataService.SortBy.CONFIDENCE, 
            ascending: filter.sorting.ascending
        });
    }

    setSortingByIOU(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter) {
        this.setSorting(filter, {
            sortBy: DeephubImagesDataService.SortBy.IOU, 
            ascending: filter.sorting.ascending
        });
    }

    setSortingAscending(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter) {
        this.setSorting(filter, {
            sortBy: filter.sorting.sortBy, 
            ascending: true
        });
    }

    setSortingDescending(filter: DeephubImagesDataService.ObjectDetectionPredictedFilter) {
        this.setSorting(filter, {
            sortBy: filter.sorting.sortBy, 
            ascending: false
        });
    }

}
