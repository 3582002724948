import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'image-placeholder',
  templateUrl: './image-placeholder.component.html',
  styleUrls: ['./image-placeholder.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePlaceholderComponent {
    @Input() hasLoaded: boolean;
    @Input() size: number;
    @Input() noImageMessage: string;

    constructor() { }
}
