import { Component, Inject, OnInit } from '@angular/core';
import { CardWizardService } from '../card-wizard.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Card, isCorrelationMatrixCard, isPCACard } from 'src/generated-sources';
import { getBasicCardTitle } from '@features/eda/card-utils';
import produce from 'immer';

enum TestState {
    ONE_SAMPLE = 'ONE_SAMPLE',
    TWO_SAMPLE = 'TWO_SAMPLE',
    N_SAMPLE = 'N_SAMPLE',
    PAIRED = 'PAIRED',
}

enum TimeSeriesAnalysisState {
    STATIONARITY = 'STATIONARITY',
    AUTOCORRELATION = 'AUTOCORRELATION',
}

export enum WizardState {
    ANALYSIS = 'ANALYSIS',
    TEST = 'TEST',
    FIT = 'FIT',
    VARIABLES = 'VARIABLES',
    MULTIVARIATE = 'MULTIVARIATE',
    TIME_SERIES = 'TIME_SERIES',
    AUTOMAGIC = 'AUTOMAGIC'
}

@Component({
    selector: 'new-card-modal',
    templateUrl: './new-card-modal.component.html',
    styleUrls: [
        '../../../shared-styles/card-wizard.less',
        './new-card-modal.component.less'
    ],
    providers: [CardWizardService]
})
export class NewCardModalComponent implements OnInit {
    state = WizardState.ANALYSIS;
    previousState = WizardState.ANALYSIS;
    WizardState = WizardState;
    selectedTest = TestState.ONE_SAMPLE;
    TestState = TestState;
    selectedTimeSeriesAnalysis = TimeSeriesAnalysisState.STATIONARITY;
    TimeSeriesAnalysisState = TimeSeriesAnalysisState;
    stateName = {
        [WizardState.ANALYSIS]: 'Select a card type',
        [WizardState.TEST]: 'Statistical Tests',
        [WizardState.FIT]: 'Fit curves & distributions',
        [WizardState.MULTIVARIATE]: 'Multivariate analysis',
        [WizardState.VARIABLES]: '',
        [WizardState.TIME_SERIES]: 'Time series analysis',
        [WizardState.AUTOMAGIC]: 'Let Dataiku suggest cards',
    };
    title: string = this.stateName[this.state];

    params: any = {};
    changedParamsValid = true;
    validationTooltip: string;
    changedParams?: Card;

    constructor(
        private cardWizardService: CardWizardService,
        private dialogRef: MatDialogRef<NewCardModalComponent>,
        @Inject('$rootScope') private $rootScope: any,
    ) {
    }

    ngOnInit() {
    }

    get isTimeSeriesSupportEnabled() {
        return this.$rootScope.featureFlagEnabled("edaTs");
    }

    setCardType(type: Card['type']) {
        this.params = this.cardWizardService.getCardDefault(type);
        this.title = getBasicCardTitle(this.params);
        this.goToState(WizardState.VARIABLES);
    }

    goToState(state: WizardState) {
        this.previousState = this.state;
        this.state = state;

        if (this.state !== WizardState.VARIABLES) {
            this.title = this.stateName[this.state];
        }
    }

    goToPreviousState(state: WizardState) {
        let previousState: WizardState = this.previousState;

        switch (state) {
            case WizardState.FIT:
            case WizardState.TEST:
            case WizardState.TIME_SERIES:
            case WizardState.MULTIVARIATE:
                previousState = WizardState.ANALYSIS;
                break;
        }

        this.goToState(previousState);
    }

    setTestType(type: TestState) {
        this.selectedTest = type;
    }

    setTimeSeriesAnalysis(type: TimeSeriesAnalysisState) {
        this.selectedTimeSeriesAnalysis = type;
    }

    changeValidity(validity: boolean) {
        this.changedParamsValid = validity;
    }

    changeValidityTooltip(tooltip: string) {
        this.validationTooltip = tooltip;
    }

    changeParams(params: Card) {
        this.changedParams = params;
    }

    dismiss() {
        this.dialogRef.close('');
    }

    get canSave() {
        return this.changedParams && this.changedParamsValid;
    }

    save() {
        if (this.canSave) {
            this.dialogRef.close({
                card: withSmartCreationParams(this.changedParams!)
            });
        }
    }
}

// Not all parameters are configurable in card creation modal
// This function overrides default parameters with smart values
function withSmartCreationParams(newCard: Card): Card {
    // Hide values when the heatmap is too large (unreadable)
    if (isCorrelationMatrixCard(newCard) || isPCACard(newCard)) {
        return produce(newCard, draft => {
            if (newCard.columns.length > 20) {
                draft.heatmapParams.showValues = false;
            }
        });
    }

    return newCard;
}
