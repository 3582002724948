import {CardResult} from './card-result';
import {Card} from './card';
import {UnitRootTestCard, _UnitRootTestCard} from './unit-root-test-card';
import {UnitRootTestZA} from './../../compute/computations/timeseries/unit-root-test-za';

/**
 * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard
 */
export interface UnitRootTestZACard extends _UnitRootTestCard {
    regressionMode: UnitRootTestZA.RegressionMode;

    // PolyJson type
    type: 'unit_root_test_za';
}

export function isUnitRootTestZACard(toTest: Card): toTest is UnitRootTestZACard { return 'unit_root_test_za' === toTest.type; }

export namespace UnitRootTestZACard {
    /**
     * Generated from com.dataiku.dip.eda.worksheets.cards.UnitRootTestZACard$UnitRootTestZACardResult
     */
    export interface UnitRootTestZACardResult extends UnitRootTestCard._UnitRootTestCardResult {
        // PolyJson type
        type: 'unit_root_test_za';
    }

    export function isUnitRootTestZACardResult(toTest: CardResult): toTest is UnitRootTestZACardResult { return 'unit_root_test_za' === toTest.type; }
}