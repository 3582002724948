import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { assertNever } from "dku-frontend-core";
import { UnitRootTestCard, isUnitRootTestADFCard, isUnitRootTestZACard, UnitRootTestADF, UnitRootTestZA, UnitRootTestKPSS, isUnitRootTestKPSSCard } from "src/generated-sources";

@Component({
    selector: 'unit-root-test-card-body',
    templateUrl: './unit-root-test-card-body.component.html',
    styleUrls: [
        '../../../../shared-styles/test-conclusion.less',
        '../../../../shared-styles/stats-table.less',
        '../../../../shared-styles/card-layout.less',
        './unit-root-test-card-body.component.less'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitRootTestCardBodyComponent {
    @Input() params: UnitRootTestCard;
    @Input() results: UnitRootTestCard.UnitRootTestCardResult;

    get isRejected() {
        return this.results.pValue <= (1 - this.params.confidenceLevel);
    }

    get isADF(): boolean {
        return isUnitRootTestADFCard(this.params);
    }

    get isZA(): boolean {
        return isUnitRootTestZACard(this.params);
    }

    get isKPSS(): boolean {
        return isUnitRootTestKPSSCard(this.params);
    }

    get nullHypothesisLabel(): string {
        const card = this.params;

        if (isUnitRootTestADFCard(card)) {
            return "The series has a unit root";
        }

        if (isUnitRootTestZACard(card)) {
            return "The series is has a unit root with a structural break";
        }

        if (isUnitRootTestKPSSCard(card)) {
            return `The series is ${this.stationarityLabel}`;
        }

        assertNever(card);
    }

    get regressionLabel(): string {
        const card = this.params;

        if (isUnitRootTestADFCard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestADF.RegressionMode.CONSTANT_ONLY:
                    return 'Constant only';
                case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_TREND:
                    return 'Constant, linear trend';
                case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_QUADRATIC_TREND:
                    return 'Constant, linear and quadratic trend';
                case UnitRootTestADF.RegressionMode.NO_CONSTANT_NO_TREND:
                    return 'No constant, no trend';
            }
        }

        if (isUnitRootTestZACard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestZA.RegressionMode.CONSTANT_ONLY:
                    return 'Constant only';
                case UnitRootTestZA.RegressionMode.TREND_ONLY:
                    return 'Trend only';
                case UnitRootTestZA.RegressionMode.CONSTANT_WITH_TREND:
                    return 'Constant with trend';
            }
        }

        if (isUnitRootTestKPSSCard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestKPSS.RegressionMode.CONSTANT:
                    return "Constant only";
                case UnitRootTestKPSS.RegressionMode.CONSTANT_WITH_TREND:
                    return "Constant with trend";
            }
        }

        assertNever(card);
    }

    get stationarityLabel(): string {
        const card = this.params;

        if (isUnitRootTestADFCard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestADF.RegressionMode.NO_CONSTANT_NO_TREND:
                case UnitRootTestADF.RegressionMode.CONSTANT_ONLY:
                    return "stationary";

                case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_TREND:
                case UnitRootTestADF.RegressionMode.CONSTANT_WITH_LINEAR_QUADRATIC_TREND:
                    return "trend stationary";
            }
        }

        if (isUnitRootTestZACard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestZA.RegressionMode.CONSTANT_ONLY:
                    return "break stationary";

                case UnitRootTestZA.RegressionMode.TREND_ONLY:
                case UnitRootTestZA.RegressionMode.CONSTANT_WITH_TREND:
                    return "trend and break stationary";
            }
        }

        if (isUnitRootTestKPSSCard(card)) {
            switch (card.regressionMode) {
                case UnitRootTestKPSS.RegressionMode.CONSTANT:
                    return "stationary";
                case UnitRootTestKPSS.RegressionMode.CONSTANT_WITH_TREND:
                    return "trend-stationary";
            }
        }

        assertNever(card);
    }
}
