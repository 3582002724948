<p>
    The Durbin-Watson statistic tests that there is no serial correlation in
    the residuals defined by <b>{{ params.seriesColumn.name }}</b>. The
    statistic is always between 0 and 4.
</p>

<p>
    There are three possible outcomes depending on the statistic:
</p>

<ul>
    <li>
        <b>the closer to 0 the statistic</b>,
        the more evidence for positive serial correlation.
    </li>
    <li>
        <b>the closer to 4 the statistic</b>,
        the more evidence for negative serial correlation.
    </li>
    <li>
        <b>the closer to 2 the statistic</b>,
        the more evidence for no serial correlation.
    </li>
</ul>

<p>
    A rule of thumb is that Durbin-Watson statistic values in the range of 1.5
    to 2.5 do not show enough evidence for serial correlation.
</p>
