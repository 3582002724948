import { Injectable } from '@angular/core';
import { DeepHubColumnFormat } from "src/generated-sources";
import { fabric } from "fabric";
import { DeephubObjectDetectionDesignCellData } from './deephub-object-detection-design-data-fetcher.service';
import { BoxHelper } from '@features/deephub/object-detection/utils/box-helper';
import { ImagePositionInformation } from '@shared/models/painter';
import { PainterService } from '@shared/services/painter.service';
import { ColorMapContextService } from '@shared/services/color-map-context.service';

@Injectable()
export class DeephubObjectDetectionDesignCanvasPainterService extends PainterService<fabric.StaticCanvas> {
    constructor(
        private colorMapService: ColorMapContextService
    ) {
        super();
    }
    
    paint(cellData: DeephubObjectDetectionDesignCellData, canvas: fabric.StaticCanvas, imagePosition: ImagePositionInformation): void {
        cellData.target?.forEach((elem: DeepHubColumnFormat.ObjectDetectionTargetItem) => {
            const box = BoxHelper.createBox(fabric.Rect, elem, imagePosition, {
                stroke: this.colorMapService.mapping.get(elem.category),
                strokeWidth: 2,
                fill: 'rgba(0, 0, 0, 0)'
            });
            if (box !== null) {
                canvas.add(box);
            }
        });
    }
}
