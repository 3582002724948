import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from "@angular/core";
import { Workspace } from "@model-main/workspaces/workspace";

@Component({
    selector: 'workspace-general-edition',
    templateUrl: './workspace-general-edition.component.html',
    styleUrls: ['./workspace-general-edition.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceGeneralEditionComponent implements OnInit {
    stockColors = [ '#540d6e', '#ee4266', '#ffd23f', '#3bceac', '#43aa8b', '#118ab2', '#073b4c' ];
    initials: string;

    @Input() workspace: Workspace;
    @Input() editionMode?: boolean;
    @Output() workspaceChange = new EventEmitter<Workspace>();

    constructor (
        // TODO migrate workspaces services to Angular
        @Inject('WorkspaceDisplayService') private WorkspaceDisplayService: any,
    ) {
    }

    ngOnInit(): void {
        if (!this.workspace.color) {
            this.workspace.color = this.stockColors[Math.floor(Math.random() * this.stockColors.length)];
        }
        this.updateInitials();
    }

    onDisplayNameChange(): void {
        if (!this.editionMode) {
            this.updateWorkspaceKey()
        }
        this.updateInitials();
        this.onWorkspaceChange();
    }

    onWorkspaceChange(): void {
        this.workspaceChange.emit({...this.workspace});
    }

    updateWorkspaceKey(): void {
        this.workspace.workspaceKey = this.workspace.displayName ? this.workspace.displayName.toUpperCase().replace(/\W+/g, "_") : '';
    }

    updateInitials(): void {
        this.initials = this.WorkspaceDisplayService.getWorkspaceInitials(this.workspace);
    }

    pickStockColor(color: string): void {
        this.workspace.color = color;
        this.onWorkspaceChange();
    }

}
