<feed-image-modal [imageId]="data.imageId" [columns]="data.columns">
    <ng-template let-image="image">
        <feed-image-modal-section [sectionTitle]="'Path'">
            <div class="alert alert-error mbot8" *ngIf="!image.valid">
                <i class="icon-dku-error mright4"></i> Path not found in managed folder
            </div>
            <p class="break-all">{{ image?.cellData?.itemPath }}</p>
        </feed-image-modal-section>
        <feed-image-modal-section [sectionTitle]="'Metadata'">
            <image-metadata [image]="image"></image-metadata>
        </feed-image-modal-section>
        <deephub-image-details [categories]="image?.cellData?.target | pluck:'category'"></deephub-image-details>
    </ng-template>
</feed-image-modal>