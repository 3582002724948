import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiViewDirective } from '@migration/ui-view/ui-view.component';
import { MarkdownDirective } from './markdown/markdown.directive';
import { AngularJSAutocompletableTextareaDirective } from './markdown/angularjs-autocompletable-textarea.directive';
import { AutocompletableTextareaComponent } from './markdown/autocompletable-textarea.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        UiViewDirective,
        MarkdownDirective,
        AngularJSAutocompletableTextareaDirective,
        AutocompletableTextareaComponent,
    ],
    exports: [
        UiViewDirective,
        MarkdownDirective,
        AutocompletableTextareaComponent,
    ],
})
export class MigrationModule { }
